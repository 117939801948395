import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthorizationFacade } from '../../facades/authorization.facade';
import { skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { I18nService } from '../../services';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../analytics/analytics.service';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { IStoreSelectionStore } from '../../models/common.models';
import { FEATURE_CONFIG } from '../../configurations/common';

@Component({
  selector: 'app-store-selection',
  templateUrl: './store-selection.component.html',
  styleUrls: ['./store-selection.component.scss'],
})
export class StoreSelectionComponent implements OnInit, OnDestroy {
  storeSelectionBannerKey: string = environment.storeSelectionBannerKey;
  stores: IStoreSelectionStore[];

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private authorizationFacade: AuthorizationFacade,
              private i18nService: I18nService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private configurationFacade: ConfigurationFacade) {
  }

  ngOnInit(): void {
    this.getTranslationByKey();
    this.authorizedUserLoggedIn();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Get stores and their attributes for world map - whether it is active etc.
   *
   * @returns {IStoreSelectionStore}
   */
  getTranslationByKey(): any {
    this.configurationFacade.getTranslationByKey([`${FEATURE_CONFIG}stores`])
      .pipe(
        skipWhile(storesArakhConfiq => typeof storesArakhConfiq[`${FEATURE_CONFIG}stores`] !== "object"),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(storesArakhConfiq => {
        this.stores = environment.stores.filter(store => store.active).map(store => {
          return {
            name: storesArakhConfiq[`${FEATURE_CONFIG}stores`][store.storeId]['worldmap-btn'].text,
            path: `${store.languages[0].code}-${store.marketCode}`,
            id: store.storeId,
            redirect: storesArakhConfiq[`${FEATURE_CONFIG}stores`][store.storeId]['worldmap-btn'].clickable === 'true',
            visible: storesArakhConfiq[`${FEATURE_CONFIG}stores`][store.storeId]['worldmap-btn'].visible === 'true',
          };
        });
      });
  }

  /**
   * User remains logged in even if he opens world map - because he still owns valid auth token and shouldn't be
   * logged out. If user is logging out then clear local storage and accordingly update analytics service.
   */
  authorizedUserLoggedIn(): void {
    this.authorizationFacade.selectIsUserLoggedIn().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      if (this.isOnLoggedOutRedirect()) {
        const currentLocale = this.i18nService.getCurrentLocale();
        this.router.navigate([currentLocale]).then();
      }

      if (!this.isOnLoggedInRedirect() && !this.isOnLoggedOutRedirect()) {
        const lang: string = LocalStorageUtils.getKeyValue('lang');

        LocalStorageUtils.clearKey(`lang-update-date-${lang}`);
        LocalStorageUtils.clearKey(`lang-data-${lang}`);
        LocalStorageUtils.clearKey(`feature-toggles-data-${lang}`);
        LocalStorageUtils.clearKey(`config-data-${lang}`);
        LocalStorageUtils.clearKey(`lang`);
        LocalStorageUtils.clearKey(`storeId`);
        LocalStorageUtils.clearKey(`previousPage`);

        this.adlyticTrackPage();
      }
    });
  }

  /**
   * Check if user is authorized => url contains "code" and "state".
   *
   * @returns {boolean}
   */
  isOnLoggedInRedirect(): boolean {
    const params: string = window.location.search;
    return params.includes('code=') && params.includes('state=');
  }

  /**
   * @returns {boolean}
   */
  isOnLoggedOutRedirect(): boolean {
    return window.location.search.includes('logoutRedirect=1');
  }

  private adlyticTrackPage(): void {
    this.analyticsService.setProducts(null);
    this.analyticsService.setCartId(null);
    this.analyticsService.trackPageReady('Store Selection', PageTypes.GLOBAL_HOME_PAGE);
  }
}
