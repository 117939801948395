import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { State } from '../../reducers';
import { IOrderItem } from '../../models/order.models';
import { ArrayUtils } from '../../utils/array.utils';
import { CustomerFacade } from '../../facades/customer.facade';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { EFeatureToggles, EStoreTypes, EUserRoles, HospitalOrdersTabs } from '../../configurations/common';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { IRfqBaseInfo, IRfqCartItem } from '../../models/rfq.models';
import { UiUtils } from '../../utils/ui.utils';
import { EQuoteRequestStatus, EScenarioType } from '../../configurations/order-status';
import { StoreConfigurationFacade } from '../../facades/store-configuration.facade';
import { CartUtils } from '../../utils/cart.utils';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { AppUtils } from '../../utils/app.utils';

@Component({
  selector: 'app-page-request-tracking-history',
  templateUrl: './page-request-tracking-history.component.html',
  styleUrls: ['./page-request-tracking-history.component.scss'],
})
export class PageRequestTrackingHistoryComponent implements OnInit, OnDestroy {
  quoteDetails: IRfqBaseInfo;
  totalQuantity = 0;
  estimatedArrivalDate: string;
  isLoaded: boolean;
  companyRoles: EUserRoles[];
  showModalCancelOrder = false;
  orderId: string;
  isRfqOnly = false;
  quoteRequestType = EScenarioType.REQUEST;
  generatedQuoteType = EScenarioType.GENERATED_QUOTE;
  private unsubscribe$ = new Subject<void>();

  protected readonly EStoreTypes = EStoreTypes;
  protected readonly AppUtils = AppUtils;

  //Feature toggles
  isHarmonizedPuchaseActivityEnabled$: Observable<boolean> =
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_PURCHASE_ACTIVITY_PAGE);

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private customerFacade: CustomerFacade,
    private analyticsService: AnalyticsService,
    private checkoutFacade: CheckoutFacade,
    private storeConfigurationFacade: StoreConfigurationFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCompanyRoles();
    this.selectIsRfqOnly();
    this.retrieveQuoteRequestDetails(this.route.snapshot.paramMap.get('requestId'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  retrieveQuoteRequestDetails(id: string): void {
    this.isLoaded = false;
    this.checkoutFacade.selectQuoteDetails(id).pipe(take(1)).subscribe({
      next: data => {
        if (data) {
          this.quoteDetails = data.data as IRfqBaseInfo;
          const date = Date.parse(this.quoteDetails.attributes.createdAt);
          const resultDate = new Date(date);
          resultDate.setDate(resultDate.getDate() + 2);
          this.estimatedArrivalDate = resultDate.toString();
          const items = Array.from(Object.create(this.quoteDetails.attributes.shownVersion.cart.items)) as IOrderItem[];
          this.quoteDetails.attributes.shownVersion.cart.items = this.combineSameItems(items);
          this.setAnalyticsInfo();
          this.totalQuantity = items.reduce((prev, curr) => {
            return prev + curr.quantity;
          }, 0);
          this.orderId = this.quoteDetails.id;
        }
      },
      complete: () => {
        this.isLoaded = true;
      },
    });
  }

  setAnalyticsInfo(): void {
    this.analyticsService.setProducts(this.quoteDetails.attributes.shownVersion.cart.items);
    this.analyticsService.setCartId(this.quoteDetails.id);
    this.analyticsService.trackOrderApproved('Order Details', PageTypes.ORDER_DETAILS);
  }

  combineSameItems(items): IRfqCartItem[] {
    return ArrayUtils.uniqueObjects(items, 'sku');
  }

  backToOrders(): void {
    this.isHarmonizedPuchaseActivityEnabled$.subscribe(
      isHarmonizedPurchaseActivityPageEnabled => {
        if (isHarmonizedPurchaseActivityPageEnabled) {
          this.router.navigate(['/purchase-activity']);
        } else {
          let queryParams;
          if (this.isGeneratedQuoteWorkflow()) {
            queryParams = {tab: HospitalOrdersTabs.PREVIOUS_QUOTES};
          } else {
            queryParams = {tab: HospitalOrdersTabs.PREVIOUS_REQUESTS};
          }
          this.router.navigate(['/my-orders'], {queryParams});
        }
      }
    )
  }

  selectCompanyRoles(): void {
    this.customerFacade.getCustomerCompanyRoles().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(companyRoles => {
      this.companyRoles = companyRoles;
    });
  }

  selectIsRfqOnly(): void {
    this.storeConfigurationFacade.selectIsRfqOnly().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isRfqOnly = state;
    });
  }

  isApprover(): boolean {
    return this.companyRoles.includes(EUserRoles.Approver) || this.companyRoles.includes(EUserRoles.Admin);
  }

  cancelQuoteRequestModal(): void {
    if (this.quoteDetails.attributes.status === EQuoteRequestStatus.REQUESTED) {
      this.showModalCancelOrder = true;
    }
  }

  cancelQuoteRequest(orderId: string): void {
    const data = {
      data: {
        type: 'quote-request-cancel',
        attributes: {},
      },
    };
    this.checkoutFacade.cancelRequest(orderId, data)
      .pipe(take(1))
      .subscribe({
          next: () => {
            this.showModalCancelOrder = false;
            this.backToOrders();
          },
          error: () => {
            this.showModalCancelOrder = false;
          },
        },
      );
  }

  restartShowModal(): void {
    this.showModalCancelOrder = false;
  }

  statusIcon(): string {
    return UiUtils.getStatusIcon(this.quoteDetails.attributes.status);
  }

  cancelButtonDisabled(): boolean {
    return this.quoteDetails.attributes.status !== EQuoteRequestStatus.REQUESTED;
  }

  isGeneratedQuoteWorkflow(): boolean {
    return this.isRfqOnly && CartUtils.isSetPricesForAllItems(this.quoteDetails.attributes.shownVersion.cart.items);
  }

  getTranslationGroup(): string {
    return this.isGeneratedQuoteWorkflow() ? 'quote-tracking' : 'request-tracking';
  }
}
