<div class="container">
  <ng-container *ngIf="!isOnLoggedInRedirect() && !isOnLoggedOutRedirect(); else loading">
    <app-content-banner [bannerKey]="storeSelectionBannerKey"></app-content-banner>
    <h3>Please select your preferred store</h3>
    <app-store-selection-map class="store-selection__map" [stores]="stores"></app-store-selection-map>
    <ul class="store-selection__list">
      @for (store of stores; track store) {
        @if (store.visible) {
          @if (store.redirect) {
            <li><a href="/{{ store.path }}/">{{ store.name }}</a></li>
          } @else {
            <li><a>{{ store.name }}</a></li>
          }
        }
      }
    </ul>
  </ng-container>
  <ng-template #loading>
    <div class="row">
      <div class="col-12 loading__wrapper">
        <div class="loading-animation"></div>
      </div>
    </div>
  </ng-template>
</div>
