import { Injectable } from '@angular/core';
import { Auth0Client, createAuth0Client, RedirectLoginResult, User } from '@auth0/auth0-spa-js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LocalStorageUtils } from '../utils/localStorage.utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  AUTH0_COOKIE: string = `auth0.${environment.auth0Config.client_id}.is.authenticated`;
  requestedScopes: string = 'openid profile email';
  token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private auth0Client: Auth0Client = new Auth0Client({
    domain: environment.auth0Config.domain,
    clientId: environment.auth0Config.client_id,
    authorizationParams: {
      redirect_uri: `${window.location.origin}`,
      audience: environment.auth0Config.audience,
      scope: this.requestedScopes,
      refresh_token: {
        rotation_type: 'rotating',
        expiration_type: 'expiring',
        token_lifetime: '2592000',
        leeway: 3,
      },
    },
    useRefreshTokensFallback: true,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  });

  isAuthenticated$: Observable<boolean> = from(this.auth0Client.isAuthenticated());

  /**
   * Convert promise output of handleRedirectCallback() to Observable. Must be a function which is returning
   * Observable<RedirectLoginResult>.
   *
   * @returns {Observable<RedirectLoginResult>}
   */
  handleRedirectCallback(): Observable<RedirectLoginResult> {
    return from(this.auth0Client.handleRedirectCallback());
  }

  getTokenSilently$: Observable<string> = from(this.getAuth0SilentToken());

  /**
   * Get silent token (acquired from Teamplay fleet or while logging in repeatedly). If "auth0Client" is not existing,
   * then create new one. If there is not existing any silent token, then returns empty string.
   *
   * @returns {Promise<string>}
   */
  async getAuth0SilentToken(): Promise<string> {
    let silentToken: string = '';
    try {
      if (!this.auth0Client) {
        this.auth0Client = await createAuth0Client({
          domain: environment.auth0Config.domain,
          clientId: environment.auth0Config.client_id,
          authorizationParams: {
            redirect_uri: `${window.location.origin}`,
            audience: environment.auth0Config.audience,
            scope: this.requestedScopes,
          },
          useRefreshTokensFallback: true,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
        });
      }
      this.token.next(await this.auth0Client.getTokenSilently({
        timeoutInSeconds: 1,
      }));
      this.token.pipe().subscribe(async (token: string): Promise<void> => {
        if (token) {
          silentToken = token;
        }
      });
      return silentToken;
    } catch (e) {}
  }

  /**
   * @returns {Observable<User>}
   */
  getUser$(): Observable<User> {
    return from(this.auth0Client.getUser());
  }

  /**
   * Login and redirect user to given "redirectPath."
   *
   * @param {string} redirectPath
   * @param {{}} queryParameters
   */
  login(redirectPath: string = '/', queryParameters = {}): void {
    LocalStorageUtils.clearKey(`lang`);
    LocalStorageUtils.clearKey(`previousPage`);

    from(this.auth0Client.loginWithRedirect({
      appState: {target: redirectPath, queryParams: queryParameters},
    }));
  }

  /**
   * Logout user - clear values from local storage.
   *
   * @param {boolean} skipRedirect
   */
  logout(skipRedirect: boolean): Observable<void> {
    if (skipRedirect) {
      LocalStorageUtils.clearKey(`lang`);
      LocalStorageUtils.clearKey(`previousPage`);
    }
    return from(this.auth0Client.logout({
      clientId: environment.auth0Config.client_id,
      logoutParams: {
        returnTo: `${window.location.origin}${skipRedirect ? '' : '?logoutRedirect=1'}`,
      },
    }));
  }

  getAuthenticatedCookie(): boolean {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${this.AUTH0_COOKIE}=`) && item.includes(`${this.AUTH0_COOKIE}=true`));
  }
}
