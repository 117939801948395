import { environment as stores } from './environment.stores';
import { environment as defaultEnvironment } from './environment.defaults';

export const environment = {
  ...stores,
  ...defaultEnvironment,
  name: 'dev',
  glueApiUrl: [
    {store: 'AU', url: 'https://dev.au.glue.backoffice.siemens-healthineers.com'},
    {store: 'BR', url: 'https://dev.br.glue.backoffice.siemens-healthineers.com'},
    {store: 'CA', url: 'https://dev.ca.glue.backoffice.siemens-healthineers.com'},
    {store: 'GB', url: 'https://dev.gb.glue.backoffice.siemens-healthineers.com'},
    {store: 'IN', url: 'https://dev.in.glue.backoffice.siemens-healthineers.com'},
    {store: 'US', url: 'https://dev.us.glue.backoffice.siemens-healthineers.com'},
    {store: 'JP', url: 'https://dev.jp.glue.backoffice.siemens-healthineers.com'},
    {store: 'EU', url: 'https://dev.eu.glue.backoffice.siemens-healthineers.com'}
  ],
  auth0Config: {
    domain: 'shs.login-qa.siemens-healthineers.com',
    client_id: 'oeyCXvwwp96G6gf7BJi7r2yX6dZQDkfo',
    useRefreshTokens: true,
    audience: 'dev.br.glue.siemens-healthineers.com',
  }
};
