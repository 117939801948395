export enum EOrderStatus {
  DRAFT                   = 'draft',
  REQUESTED               = 'requested',
  GENERATED               = 'generated',
  REQUEST                 = 'request',
  NEW                     = 'new',
  CREATED                 = 'created',
  IN_PROCESS              = 'in process',
  CONFIRMED               = 'confirmed',
  PARTIALLY_SHIPPED       = 'partially shipped',
  SHIPPED                 = 'shipped',
  INVOICE_SENT            = 'invoice sent',
  PAYMENT_PROCESSED       = 'payment processed',
  COMPLETED               = 'completed',
  RECEIVED                = 'received',
  WAITING                 = 'waiting',
  DECLINED                = 'declined',
  CANCELED                = 'canceled',
  PAYMENT_CANCELED        = 'payment cancelled',
  PAYMENT_FAILED          = 'payment failed',
  PAYMENT_PENDING         = 'payment pending',
  PAYMENT_SUCCESSFUL      = 'payment successful',
  ISSUE                   = 'issue',
  SEE_DETAILS             = 'see details',
  ORDER_PROCESSED         = 'order processed',
  ORDER_SUBMITTED         = 'order submitted',
  PENDING_DOCUMENTATION   = 'pending documentation',
  DOCUMENTATION_SUBMITTED = 'documentation submitted',
  DOCUMENT_ACCEPTED       = 'document accepted',
  UNDER_REVIEW            = 'under review',
  ORDER_COMPLETE          = 'order complete',
  ISSUE_WITH_ORDER        = 'issue with the order',
  CPQ_ORDER_DECLINED      = 'order declined'
}

export enum EHybrisOrderStatus {
  ORDER_CREATED          = 'order created',
  ON_HOLD                = 'on hold',
  BACKORDERED            = 'backordered',
  SCHEDULED_FOR_SHIPMENT = 'scheduled for shipment',
  IN_PROCESS             = 'in process',
  PARTIALLY_SHIPPED      = 'partially shipped',
  SHIPPED                = 'shipped',
  REJECTED               = 'rejected',
}

export enum EQuoteRequestStatus {
  REQUESTED = 'requested',
  GENERATED = 'generated',
  RECEIVED  = 'received',
  CANCELED  = 'canceled',
  WAITING   = 'waiting',
  CLOSED    = 'closed'
}

export enum EScenarioType {
  ORDERS                   = 'orders',
  REQUEST                  = 'quote-requests',
  GENERATED_QUOTE          = 'generated-quote',
  CPQ                      = 'cpq',
  SPARE_PART_ORDER_DETAILS = 'spare-parts-order-details'
}

export interface IOrderStatusScenario {
  scenarioSteps: EOrderStatus[];
  hospitalSteps?: EOrderStatus[];
  siemensStep?: EOrderStatus;
  organizationSteps?: EOrderStatus[];
}

export class OrderStatus {
  static ShippedSparePartOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.CREATED,
      EOrderStatus.SHIPPED,
    ],
  };

  static PartiallyShippedSparePartOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.CREATED,
      EOrderStatus.PARTIALLY_SHIPPED,
    ],
  };

  static CanceledSparePartOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.CREATED,
      EOrderStatus.CANCELED,
    ],
  };

  static IssueSparePartOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.CREATED,
      EOrderStatus.ISSUE,
    ],
  };

  static SeeDetailsSparePartOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.CREATED,
      EOrderStatus.SEE_DETAILS,
    ],
  };

  static DeclineScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.NEW,
      EOrderStatus.DECLINED,
    ],
  };
  static CanceledOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.CANCELED,
    ],
  };
  static CanceledCCAvenuePaymentScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.PAYMENT_CANCELED,
    ],
  };
  static FailedCCAvenuePaymentScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.PAYMENT_FAILED,
    ],
  };
  static SuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.CONFIRMED,
      EOrderStatus.SHIPPED,
      EOrderStatus.INVOICE_SENT,
      EOrderStatus.COMPLETED,
    ],
    hospitalSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
    ],
    siemensStep: EOrderStatus.CONFIRMED,
  };
  static IndiaSuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.CONFIRMED,
      EOrderStatus.PAYMENT_PROCESSED,
      EOrderStatus.ORDER_PROCESSED,
      EOrderStatus.COMPLETED,
    ],
    hospitalSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
    ],
    siemensStep: EOrderStatus.CONFIRMED,
  };

  static IndiaCCAvenueSuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.CONFIRMED,
      EOrderStatus.PAYMENT_PROCESSED,
      EOrderStatus.ORDER_PROCESSED,
      EOrderStatus.COMPLETED,
    ],
    hospitalSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
    ],
    siemensStep: EOrderStatus.CONFIRMED,
  };

  static JapanSuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
      EOrderStatus.CONFIRMED,
      EOrderStatus.SHIPPED,
      EOrderStatus.INVOICE_SENT,
    ],
    hospitalSteps: [
      EOrderStatus.REQUEST,
      EOrderStatus.NEW,
    ],
    siemensStep: EOrderStatus.CONFIRMED,
  };
  static RequestSuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUESTED,
      EOrderStatus.RECEIVED,
    ],
  };
  static RequestCanceledScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.REQUESTED,
      EOrderStatus.CANCELED,
    ],
  };
  static GeneratedQuoteSuccessScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.GENERATED,
      EOrderStatus.RECEIVED,
    ],
  };
  static GeneratedQuoteCanceledScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.GENERATED,
      EOrderStatus.CANCELED,
    ],
  };
  static CpqPendingScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.PENDING_DOCUMENTATION,
      EOrderStatus.DOCUMENT_ACCEPTED,
      EOrderStatus.ORDER_COMPLETE,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.PENDING_DOCUMENTATION,
    ],
    siemensStep: EOrderStatus.DOCUMENT_ACCEPTED,
  };
  static CpqReviewScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
      EOrderStatus.UNDER_REVIEW,
      EOrderStatus.ORDER_COMPLETE,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
    ],
    siemensStep: EOrderStatus.UNDER_REVIEW,
  };
  static CpqIssueWithOrderScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
      EOrderStatus.ISSUE_WITH_ORDER,
      EOrderStatus.ORDER_COMPLETE,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
    ],
    siemensStep: EOrderStatus.ISSUE_WITH_ORDER,
  };

  static CpqUnderReviewScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
      EOrderStatus.UNDER_REVIEW,
      EOrderStatus.ORDER_COMPLETE,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
    ],
    siemensStep: EOrderStatus.UNDER_REVIEW,
  };

  static CpqOrderCompleteScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
      EOrderStatus.DOCUMENT_ACCEPTED,
      EOrderStatus.ORDER_COMPLETE,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
    ],
    siemensStep: EOrderStatus.DOCUMENT_ACCEPTED,
  };

  static CpqOrderDeclinedScenario: IOrderStatusScenario = {
    scenarioSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
      EOrderStatus.ISSUE_WITH_ORDER,
      EOrderStatus.CPQ_ORDER_DECLINED,
    ],
    organizationSteps: [
      EOrderStatus.ORDER_SUBMITTED,
      EOrderStatus.DOCUMENTATION_SUBMITTED,
    ],
    siemensStep: EOrderStatus.ISSUE_WITH_ORDER,
  };
}
