import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Router, UrlSerializer, UrlTree } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthActions } from '../actions';
import { AppActions } from '../../actions/';
import { AuthService } from '../../services';
import { CustomerActions, ShopCartActions } from '../../actions';
import * as OrderApproveActions from '../../actions/order-approve.actions';
import * as OrdersActions from '../../actions/orders.actions';
import { RedirectLoginResult, User } from '@auth0/auth0-spa-js';
import { INotification } from '../../models/cpq.models';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private urlSerializer: UrlSerializer,
  ) {
  }

  handleAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.handleAuth),
      exhaustMap(() => {
        const params = window.location.search;
        if (params.includes('code=') && params.includes('state=')) {
          return this.authService.handleRedirectCallback().pipe(
            map((cbRes: RedirectLoginResult) => {
              const targetRoute = cbRes.appState?.target ?? '/';
              return AuthActions.handleRedirectSuccess({
                targetRoute,
                queryParameters: cbRes?.appState?.queryParams,
              });
            }),
            catchError(err => this.notifyError(`\n'Authentication Redirect'.\n${err}`)),
          );
        }
        return [AuthActions.checkAuth(), AuthActions.setIsLoginInProcess({isLoginInProcess: true})];
      }),
    ),
  );

  notifyError = (name: string) => this.notify({
    type: 'canceled',
    title: 'alert.error',
    messages: [
      {
        key: 'error.action',
        params: {name},
        css: ['message'],
      },
    ],
  });

  notify = (notification: INotification) => of(AppActions.appendNotification({notification}));

  handleRedirectSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.handleRedirectSuccess),
        tap(({targetRoute, queryParameters}) => {
          this.forceRedirectToTargetRoute(targetRoute, queryParameters);
        }),
      ),
    {dispatch: false},
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logIn),
        tap((action) => {
          const urlTree: UrlTree = this.router.parseUrl(this.router.url);
          const redirectPath: string = action.url !== '' ? action.url : `${window.location.pathname}`;
          this.authService.login(
            redirectPath,
            !!action.queryParameters && !!Object.keys(action.queryParameters)?.length ?
              action.queryParameters : urlTree.queryParams,
          );
        }),
      ),
    {dispatch: false},
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUser, AuthActions.checkAuthSuccess, AuthActions.loadAccessTokenSuccess),
      mergeMap(() => this.authService.getUser$()
        .pipe(
          exhaustMap((user: User) => {
            const dispatchList = [];

            if (user) {
              dispatchList.push(AuthActions.loadUserSuccess({user}));
              dispatchList.push(CustomerActions.loadCustomerData());
              dispatchList.push(AuthActions.setNotAuthenticated({isAuthenticated: true}));
            } else {
              dispatchList.push(AuthActions.setNotAuthenticated({isAuthenticated: false}));
            }

            return dispatchList;
          }),
          catchError(_ => [
            AuthActions.setNotAuthenticated({isAuthenticated: false}),
          ]),
        ),
      ),
    ),
  );

  checkAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkAuth, AuthActions.handleRedirectSuccess),
      switchMap(() => {
        return this.authService.getTokenSilently$.pipe(
          exhaustMap((token: string) => {
              const dispatchList = [];
              if (token) {
                dispatchList.push(AuthActions.loadAccessTokenSuccess({token}));
              } else {
                dispatchList.push(AuthActions.loadUser());
              }
              dispatchList.push(AuthActions.setIsLoginInProcess({isLoginInProcess: false}));
              return dispatchList;
            },
          ),
          catchError(_ => {
            const dispatchList = [];
            dispatchList.push(CustomerActions.CompanyUsersActionStart());
            dispatchList.push(ShopCartActions.clearCart());
            dispatchList.push(OrderApproveActions.successSummaryPageClearData());
            dispatchList.push(AuthActions.setNotAuthenticated({isAuthenticated: false}));
            dispatchList.push(OrdersActions.OrdersWithSapDetailsClearData());
            dispatchList.push(OrdersActions.OrdersHistoryClearDataAction());
            dispatchList.push(AuthActions.setIsLoginInProcess({isLoginInProcess: false}));

            return dispatchList;
          }),
        );
      }),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        switchMap(({skipRedirectToStore}) => {
          return this.authService.logout(skipRedirectToStore).pipe(
            map(() => AuthActions.setIsLoginInProcess({isLoginInProcess: false})),
          );
        }),
      ),
    {dispatch: false},
  );

  forceRedirectToTargetRoute(targetRoute: string, queryParameters: any): void {
    const urlTree = this.router.createUrlTree([targetRoute], {queryParams: queryParameters});
    window.location.href = this.urlSerializer.serialize(urlTree);
  }
}
