<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-sm-12 col-lg-1 hidden-sm"></div>
      <div class="col-12 col-lg-10">
        <h3 class="checkout-label" [attr.data-test]="'title-2ndDetailsPage'">
          {{ getPageTitle() | translate }}
        </h3>
        <h6 [attr.data-test]="'subtitle-2ndDetailsPage'">{{ getSecondPageTitle() | translate }}</h6>
        <br>
        <div class="row" *ngIf="(visibilityOfInputs$ |async)?.includes(inputsEnum?.DEPARTMENT)">
          <app-select-box
              class="col"
              [form]="contactForm"
              [modelValue]="contactForm.value.department"
              [options]="departments"
              [inputName]="'department'"
              label="{{ 'checkout-delivery-details.select-department-title' | translate }}"
              [isRequired]="(requiredInputs$ | async)?.includes(inputsEnum?.DEPARTMENT)"
              [search]="true"
              [searchPlaceholder]="'checkout-delivery-details.search-box-department-placeholder' | translate"
              (selected)="setFormValue($event)">
          </app-select-box>
        </div>
        <div class="row">
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.firstName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-firstname'}"
                [inputName]="'firstName'"
                label="{{ 'checkout-delivery-details.select-first-name' | translate }}"
                [isRequired]="true"
                [ngClass]="{'is-invalid': contactForm.get('firstName').errors && contactForm.get('firstName').touched}"
                (fieldFilled)="setFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('firstName').touched &&
                                        contactForm.get('firstName').hasError('maxlength')">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}</small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('firstName').hasError('pattern') &&
                                        contactForm.get('firstName').touched">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.only-letters' | translate }}</small>
              </ng-container>
            </div>
          </div>
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.lastName"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-lastname'}"
                [inputName]="'lastName'"
                label="{{ 'checkout-delivery-details.select-last-name' | translate }}"
                [isRequired]="true"
                [ngClass]="{'is-invalid': contactForm.get('lastName').errors && contactForm.get('lastName').touched}"
                (fieldFilled)="setFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('lastName').touched &&
                                        contactForm.get('lastName').hasError('maxlength')">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthName} }}</small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('lastName').hasError('pattern') && contactForm.get('lastName').touched">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.only-letters' | translate }}</small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [form]="contactForm"
                [modelValue]="contactForm.value.phone"
                [cssStyles]="{wrapper: 'textfield-with-prefix textfield-telephone'}"
                [inputName]="'phone'"
                label="{{ 'checkout-delivery-details.select-phone-number' | translate }}"
                [isRequired]="true"
                (fieldFilled)="setFormValue($event)"
                trim
                trimWhitespaces
                [multiCharToTrim]="['-']">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('phone').touched &&
                                        contactForm.get('phone').hasError('maxlength')">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.max-characters' | translate: {charNumber: maxLengthPhone} }}</small>
              </ng-container>
              <ng-container
                  *ngIf="contactForm.get('phone').hasError('pattern')">
                <small
                    class="textfield__hint">{{ 'checkout-delivery-details.only-numbers' | translate }}</small>
              </ng-container>
            </div>
          </div>
          <div class="checkout__field col-md-6">
            <app-textfield
                class="checkout__textfield col-12 col-md-6"
                [inputType]="'email'"
                [form]="contactForm"
                [modelValue]="contactForm.value.email"
                [cssStyles]="{wrapper: 'email-with-prefix email-telephone'}"
                [inputName]="'email'"
                label="{{ 'request-details.input-email' | translate }}"
                [isRequired]="true"
                (fieldFilled)="setFormValue($event)">
            </app-textfield>
            <div class="checkout__alerts">
              <ng-container
                  *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('email')">
                <small class="textfield__hint">
                  {{ 'request-details.email-pattern' | translate }}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="(visibilityOfInputs$ | async)?.includes(inputsEnum?.FLOOR_OR_ROOM)">
          <app-textfield
              class="checkout__textfield col-12"
              [form]="contactForm"
              [modelValue]="contactForm.value.floorOrRoom"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-siemensEquipmentId'}"
              [inputName]="'floorOrRoom'"
              label="{{ 'checkout-delivery-details.select-floor-or-room' | translate }}"
              [isRequired]="(requiredInputs$ | async)?.includes(inputsEnum?.FLOOR_OR_ROOM)"
              (fieldFilled)="setFormValue($event)">
          </app-textfield>
        </div>
        <div class="row">
          <app-textarea
              class="col-12"
              [form]="contactForm"
              [modelValue]="contactForm.value.comment"
              [rows]="3"
              [cssStyles]="{wrapper: 'text-area-comment'}"
              [inputName]="'comment'"
              [maxLength]="maxLengthComment"
              label="{{ 'checkout-delivery-details.comment' | translate }}"
              trim
              trimWhitespaces
              (areaFilled)="setFormValue($event)">
          </app-textarea>
          <div class="checkout__alerts">
            <ng-container *ngIf="contactForm.value.comment">
              <small class="textfield__hint">
                {{ contactForm.value.comment.length }}/{{ maxLengthComment }}
              </small>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="!isAuStore">
          <h6>{{ 'request-details.tax-number-title-ordered' | translate }}</h6>
          <br>
          <div class="row">
            <div class="checkout__field col-md-6">
              <app-textfield
                  class="checkout__textfield col-12 col-md-6"
                  [form]="contactForm"
                  [cssStyles]=""
                  [inputName]="'taxNumber'"
                  label="{{ 'request-details.tax-number-type' | translate }}"
                  [isRequired]="false"
                  [modelValue]="contactForm.value.taxNumber"
                  (fieldFilled)="setFormValue($event)">
              </app-textfield>
              <div class="checkout__alerts">
                <ng-container *ngIf="contactForm.get('taxNumber').touched &&
                                contactForm.get('taxNumber').hasError('maxlength')">
                  <small class="textfield__hint"
                         [attr.data-test]="'hint-modalMaxCharacters'">
                    {{ 'checkout-delivery-details-modal.max-characters' | translate: {charNumber: 30} }}
                  </small>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <span class="label">
          {{ 'checkout-delivery-details.mandatory-fields-required' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="container order-summary col-12 col-lg-4">
    <div class="row">
      <div class="col-12">
        <button
            appDebounceClick
            class="button button--primary button-request-quote"
            type="button"
            [disabled]="disableBtnCreateRfq()"
            [debounceTime]="300"
            (debounceClick)="proceedToCreateRfq()"
            [attr.data-test]="'button-proceedToCreateRfq'">
          {{ getTitleOfRequestButton() | translate }}
        </button>
        <button
            class="button button-previous-page"
            [ngClass]="{ 'mb-1': isRfqOnly && isSetPricesForAllItems() }"
            type="button"
            (click)="backToRequestDetails()"
            [attr.data-test]="'button-previousPage'">
          {{ 'request-details.previous-page' | translate }}
        </button>
      </div>
    </div>
    <div class="row checkbox-container-row" *ngIf="isRfqOnly && isSetPricesForAllItems()">
      <div class="col-12" [formGroup]="contactForm">
        <input
            type="checkbox"
            id="terms-and-conditions-consent"
            name="terms-and-conditions-consent"
            class="checkbox"
            [required]="true"
            formControlName="termsAndConditionsConsent"
        >
        <label for="terms-and-conditions-consent">
          {{ 'order-approve.terms-and-conditions-legal-consent' | translate }}
          <a *ngIf="termsAndConditionsUrl.type === 'internal'"
              [routerLink]="termsAndConditionsUrl.value"
              target="_blank">
            {{ 'order-approve.terms-and-conditions-link-title' | translate }}
          </a>
          <a *ngIf="termsAndConditionsUrl.type === 'external'"
              [href]="termsAndConditionsUrl.value"
              target="_blank">
            {{ 'order-approve.terms-and-conditions-link-title' | translate }}
          </a>
        </label>
      </div>
      <div class="col-12" [formGroup]="contactForm">
        <input
            type="checkbox"
            id="privacy-policy-consent"
            name="privacy-policy-consent"
            class="checkbox"
            [required]="true"
            formControlName="privacyPolicyConsent"
        >
        <label for="privacy-policy-consent">
          {{ 'order-approve.privacy-policy-legal-consent' | translate }}
          <a *ngIf="privacyPolicyUrl.type === 'internal'"
              [routerLink]="privacyPolicyUrl.value"
              target="_blank">
            {{ 'order-approve.privacy-policy-link-title' | translate }}
          </a>
          <a *ngIf="privacyPolicyUrl.type === 'external'"
              [href]="privacyPolicyUrl.value"
              target="_blank">
            {{ 'order-approve.privacy-policy-link-title' | translate }}
          </a>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-10 min-order-value" *ngIf="!isMinimumOrderValue()">
        <strong>
          {{ 'shop-cart.minimum-order-value' | translate }}
        </strong>
      </div>
      <div class="col-12">
        <ng-container *ngIf="!!getTotalPrice() && isRfqOnly && isSetPricesForAllItems()">
          <div class="row">
            <div class="col-12 col-md-8">
              <h6 class="order-summary-total-price">{{ 'checkout-delivery-details.total-price' | translate }}</h6>
            </div>
            <div class="col-12 col-md-4">
              <p class="order-summary-price"
                 [attr.data-test]="'desc-cartTotalPrice'">{{ currentCart.totals.subtotal | currency: currency }}</p>
            </div>
          </div>
          <div class="order-summary-additional-information">
            <ng-container *ngIf="showPriceDisclaimer">
              {{ 'shop-cart.excludes-vat' | translate }}<br/>
              {{ 'shop-cart.pricing-validation' | translate }}
            </ng-container>
          </div>
        </ng-container>
        <hr class="bottom-line">
        <div class="back-to-cart" (click)="backToCart()">
          <span class="icon-arrow-left"></span>
          {{ 'checkout-delivery-details.back-to-card' | translate }}
        </div>
        <div class="cart-items-header">
          <div class="cart-items-header-title" [attr.data-test]="'title-yourCart'">
            {{ 'request-details.your-cart-title' | translate }}
          </div>
        </div>
        <div *ngIf="cartItems && cartItems.length > 0" class="cart-items">
          <div class="item-row" *ngFor="let item of cartItems" [attr.data-test]="'dp2-cart-item-' + item.attributes.name">
            <div class="item-col-img">
              <img
                  [src]="getProductImageUrl(item)"
                  [alt]="item.attributes.name"
                  [attr.data-test]="'img-productImage-' + item.attributes.name">
            </div>
            <div class="item-row-attributes">
              <div class="item-row-attributes-flex">
                <div class="item-col item-col-desc"
                     [attr.data-test]="'desc-itemName-' + item.attributes.name">
                  {{ item.attributes.name }}
                </div>
              </div>
              <div class="item-row-attributes-space">
                <div class="item-row-attributes-flex">
                  <div class="item-col">
                    <span [attr.data-test]="'label-itemQuantity-' + item.attributes.name">
                      {{ 'request-details.items-quantity-title' | translate }}
                    </span>
                  </div>
                  <span class="item-col-line"
                        [attr.data-test]="'desc-itemQuantity-' + item.attributes.name">
                    {{ item.attributes.quantity }}
                  </span>
                </div>
              </div>
              <ng-container *ngIf="isRfqOnly">
                <div class="item-row-attributes-flex" *ngIf="!!getTotalPrice() && isSetPricesForAllItems()">
                  <div class="item-col" [attr.data-test]="'label-itemPrice-' + item.attributes.name">
                    {{ 'shop-cart.item-price' | translate }}
                  </div>
                  <span class="item-col-line"
                        [attr.data-test]="'desc-itemPrice-' + item.attributes.name">
                    {{ item.attributes.calculations.unitPrice | currency: currency }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
