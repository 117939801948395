import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../../services';
import { AppUtils } from '../../utils/app.utils';

@Pipe({name: 'currency'})
export class CustomerCurrencyPipe implements PipeTransform {

  constructor(private i18nService: I18nService) {
  }

  transform(value: number | string | null | undefined,
            currencyCode?: string,
            display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol-narrow',
            decimalDigit: number = -0.1,
            digitsInfo?: string,
            locale?: string,
  ): string | null {
    const newValue = (value as number) / (
      decimalDigit !== -0.1 ? Math.pow(10, decimalDigit) : AppUtils.getCurrentStore().defaultPriceDivisorValue
    );
    const currentLocale = this.i18nService.getCurrentLocale();
    if (currentLocale == 'en-in') {
      const stringValue = (value ?? 0).toString();
      const stringValueLength = stringValue.length;
      const currencySymbol = '\u{20B9}';
      let decimalPart = stringValue.slice(-2);
      if (decimalPart.length === 1) {
        decimalPart = '0' + decimalPart;
      }
      let lastThree = stringValue.substring(stringValueLength - 5, stringValueLength - 2);
      const otherNumbers = stringValue.substring(0, stringValueLength - 5);
      if (otherNumbers != '') {
        lastThree = ',' + lastThree;
      }
      if (lastThree == '') {
        lastThree = '0';
      }
      const output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + '.' + decimalPart; //NOSONAR
      return currencySymbol + output;
    }

    const formattedCurrency = new CurrencyPipe(currentLocale, currencyCode).transform(
      newValue, currencyCode, display, digitsInfo, locale);

    if (display && display === 'code') {
      const firstDigit = formattedCurrency.search(/\d/);

      return formattedCurrency.substring(0, firstDigit) + ' ' + formattedCurrency.substr(firstDigit);
    }

    return formattedCurrency;
  }
}
