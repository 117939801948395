import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import * as ValidationPatterns from '../../../configurations/validations';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { ISelectEvent } from '../../../models/common.models';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { TermConstants } from '../../../shared/terms/TermConstants';
import { AppUtils } from '../../../utils/app.utils';
import { CartUtils } from '../../../utils/cart.utils';
import { ImageUtils } from '../../../utils/image.utils';
import { Observable, Subject } from 'rxjs';
import { EDeliveryInputNames, EStoreTypes, FEATURE_TOGGLE } from '../../../configurations/common';

@Component({
  selector: 'app-contact-details-section',
  templateUrl: 'contact-details-section.component.html',
  styleUrls: ['contact-details-section.component.scss']
})
export class ContactDetailsSectionComponent implements OnInit {
  @Input() creatingOrderInProgress;
  @Input() cartItems;
  @Input() isCartEmpty;
  @Input() isRfqOnly;
  @Input() minimumOrderValue;
  @Input() currency;
  @Input() currentCart;
  @Input() isAuStore;
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() backToDetailsClicked = new EventEmitter();

  contactForm: UntypedFormGroup;
  departments: IBaseConfig[];
  maxLengthPhone = 20;
  maxLengthName = 35;
  maxLengthComment = 500;
  showPriceDisclaimer: boolean;
  requiredInputs$: Observable<string[]> = new Observable<string[]>();
  visibilityOfInputs$: Observable<string[]> = new Observable<string[]>();
  inputsEnum: {[key: string]: string};

  privacyPolicyUrl = {
    value: '', type: 'external',
  };
  termsAndConditionsUrl = {
    value: '', type: 'internal',
  };

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private customerFacade: CustomerFacade,
              private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.inputsEnum = EDeliveryInputNames;
    this.requiredInputs$ = this.configurationFacade.getArrayValues(FEATURE_TOGGLE, 'checkout_page_required_inputs');
    this.visibilityOfInputs$ = this.configurationFacade.getArrayValues(FEATURE_TOGGLE, 'delivery_details_2_visibility_of_inputs');
    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;

    this.getDepartments();
    this.initializeForm();

    this.privacyPolicyUrl = this.configurationFacade.getTermsUrl(
      TermConstants.termKeys.PRIVACY_POLICY.termsKey,
    );
    this.termsAndConditionsUrl = this.configurationFacade.getTermsUrl(
      TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey,
    );
  }

  getProductImageUrl(product: any): string {
    return ImageUtils.getProductImageUrl(product);
  }

  setFormValue(event: ISelectEvent): void {
    this.contactForm.patchValue({
      [event.key]: event.value,
    });
  }

  formIsValid(): boolean {
    if (this.isRfqOnly && this.isSetPricesForAllItems()) {
      return (this.contactForm.status === 'VALID' && this.validateTermsAndPrivacy());
    }
    else {
      return this.contactForm.status === 'VALID';
    }
  }

  proceedToCreateRfq(): void {
    const formData = {
      pointOfContact: {
        comment: this.contactForm.value.comment,
        email: this.contactForm.value.email,
        department: this.contactForm.value.department,
        floorOrRoom: this.contactForm.value.floorOrRoom,
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        phone: this.contactForm.value.phone,
        pointOfContactId: 'pointOfContact',
        deliveryTime: '',
      },
      taxNumber: this.contactForm.value.taxNumber,
    };

    if (this.isMinimumOrderValue()) {
      this.formSubmitted.emit(formData);
    }
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  backToRequestDetails(): void {
    this.backToDetailsClicked.emit();
  }

  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.cartItems);
  }

  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, this.isCartEmpty, this.currentCart, this.cartItems);
  }

  getPageTitle(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'request-quote.page-title-rfq-only' : 'request-quote.page-title';
  }

  /**
   * Get title for second page of delivery details:
   * If there is any product without price or is GB store active, then set title including point of contact.
   * If there are all products with price, then set title including shipping method.
   * If DEPARTMENT is missing on the DELIVERY 2. page, then set title - 2 Select Point of contact. Without DEPARTMENT
   *
   * @returns {string}
   */
  getSecondPageTitle(): string {
    let visibilityOfInputs: string[] = [];
    this.visibilityOfInputs$.subscribe(data => visibilityOfInputs = data);

    if (!visibilityOfInputs.includes(EDeliveryInputNames.DEPARTMENT)) {
      return 'checkout-delivery-details.select-point-of-contact-title-without-department';
    } else if (this.isRfqOnly && this.isSetPricesForAllItems() && AppUtils.isStoreActive(EStoreTypes.AU)) {
      return 'checkout-delivery-details.select-department-and-shipping-method-title';
    } else {
      return 'checkout-delivery-details.select-point-of-contact-title';
    }
  }

  getTitleOfRequestButton(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems() ? 'request-quote.request-button-rfq-only' : 'request-quote.request-button';
  }

  getTotalPrice(): number {
    return CartUtils.getTotalPrice(this.isCartEmpty, this.currentCart);
  }

  disableBtnCreateRfq(): boolean {
    return (!this.isMinimumOrderValue() && this.formIsValid()) || !this.formIsValid() || this.creatingOrderInProgress;
  }

  getDepartments(): void {
    this.configurationFacade.getTranslationByKey(['departments'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.departments = Object.values(data['departments']).map((department: string) => {
          return {
            name: department,
            value: department
          };
        });
      });
  }

  private initializeForm(): void {
    let requiredInputs: string[] = [];
    let visibilityOfInputs: string[] = [];

    this.requiredInputs$.subscribe(data => requiredInputs = data);
    this.visibilityOfInputs$.subscribe(data => visibilityOfInputs = data);

    this.contactForm = this.formBuilder.group({
      department: ['', (requiredInputs?.includes(EDeliveryInputNames?.DEPARTMENT) && visibilityOfInputs?.includes(EDeliveryInputNames?.DEPARTMENT)) ? [Validators.required] : []],
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      phone: ['', [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      email: ['', [Validators.required, Validators.email]],
      floorOrRoom: ['', (requiredInputs?.includes(EDeliveryInputNames?.FLOOR_OR_ROOM) && visibilityOfInputs?.includes(EDeliveryInputNames?.FLOOR_OR_ROOM)) ? [Validators.required] : []],
      comment: ['', [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
      taxNumber: ['', Validators.maxLength(30)],
      termsAndConditionsConsent: false,
      privacyPolicyConsent: false,
    });

    this.selectCustomerInfo();
  }

  private selectCustomerInfo(): void {
    this.customerFacade.selectMyProfileInformation().pipe(take(1))
      .subscribe(state => {
        this.contactForm.patchValue({
          ['firstName']: state.firstName || '',
          ['lastName']: state.lastName || '',
          ['email']: state.email || '',
        });
      });
  }

  private validateTermsAndPrivacy(): boolean {
    return this.contactForm.value.termsAndConditionsConsent && this.contactForm.value.privacyPolicyConsent;
  }
}
