import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BreadCrumb } from '../models/common.models';
import { INavigationNodes, INavigationResponse, IStoreAddress } from '../models/settings.model';
import {
  BreadcrumbsService,
  FooterService,
  I18nService,
  NavigationBarService,
  ProductListService,
  ProductsService,
} from '../services';
import { State } from '../reducers';
import * as AppSelectors from '../reducers/app.reducer';
import * as AppActions from '../actions/app.actions';
import { ESeoData, ETitleSources } from '../configurations/seo';
import {
  ECheckoutFlows,
  EFeatureToggles,
  EFullPageLoaderListOptions,
  ERouteDataTypes,
  EStoreFeatures,
  ETermsAndConsentsType,
  FEATURE_CONFIG,
  FEATURE_TOGGLE,
} from '../configurations/common';
import { AppUtils } from '../utils/app.utils';
import { TermsUtils } from '../utils/terms.utils';
import { environment } from '../../environments/environment';
import { ISocialMediaFooter } from '../models/footers.models';
import { HomepageActions } from '../actions';
import {
  selectShopCategoryIsAnyTileActive,
  selectShopCategoryLoadingError,
  selectShopCategoryOptions,
  selectShopCategoryOptionsLoading,
} from '../reducers/homepage.reducer';
import { INotification } from '../models/cpq.models';
import { map } from 'rxjs/operators';
import { IBaseConfig } from '../models/enviroment-delivery-details.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationFacade {
  private pNavigationBarService: NavigationBarService;
  private pFooterService: FooterService;
  private pBreadcrumbsService: BreadcrumbsService;
  private pProductService: ProductsService;
  private pProductListService: ProductListService;
  private pI18nService: I18nService;

  public get navigationBarService(): NavigationBarService {
    if (!this.pNavigationBarService) {
      this.pNavigationBarService = this.injector.get(NavigationBarService);
    }
    return this.pNavigationBarService;
  }

  public get productListService(): ProductListService {
    if (!this.pProductListService) {
      this.pProductListService = this.injector.get(ProductListService);
    }
    return this.pProductListService;
  }

  public get footerService(): FooterService {
    if (!this.pFooterService) {
      this.pFooterService = this.injector.get<FooterService>(FooterService);
    }
    return this.pFooterService;
  }

  public get breadcrumbsService(): BreadcrumbsService {
    if (!this.pBreadcrumbsService) {
      this.pBreadcrumbsService = this.injector.get<BreadcrumbsService>(BreadcrumbsService);
    }
    return this.pBreadcrumbsService;
  }

  public get productService(): ProductsService {
    if (!this.pProductService) {
      this.pProductService = this.injector.get(ProductsService);
    }
    return this.pProductService;
  }

  public get i18nService(): I18nService {
    if (!this.pI18nService) {
      this.pI18nService = this.injector.get(I18nService);
    }
    return this.pI18nService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>,
  ) {
  }

  getNavigationBar(isGuest: boolean): Observable<INavigationResponse> {
    return this.navigationBarService.getNavigationBar(isGuest);
  }

  getShopCategoryTiles(): Observable<INavigationResponse> {
    return this.navigationBarService.getShopCategoryNavigationBar();
  }

  loadShopCategoryTiles() {
    this.store.dispatch(HomepageActions.loadShopCategoryNavigation());
  }

  selectShopCategoryTiles(): Observable<INavigationNodes[]> {
    return this.store.select(selectShopCategoryOptions);
  }

  selectShopCategoryTilesLoading(): Observable<boolean> {
    return this.store.select(selectShopCategoryOptionsLoading);
  }

  selectShopCategoryTilesError(): Observable<string> {
    return this.store.select(selectShopCategoryLoadingError);
  }

  selectShopCategoryIsAnyTileActive(): Observable<boolean> {
    return this.store.select(selectShopCategoryIsAnyTileActive);
  }

  getFooterNavigation(): Observable<any> {
    return this.footerService.getFooterNav();
  }

  getSocialMediaFooter(): Observable<ISocialMediaFooter> {
    return this.footerService.getSocialMediaFooter();
  }

  getBreadcrumbs(route: ActivatedRoute): BreadCrumb[] {
    return this.breadcrumbsService.getBreadcrumbs(route);
  }

  getBreadcrumbsParam(route: ActivatedRoute, param: ESeoData | ERouteDataTypes): ETitleSources | boolean {
    return this.breadcrumbsService.getBreadcrumbsParam(route, param);
  }

  getProductWithCategoryTree(sku: string): Promise<any> {
    return this.productService.getProductWithCategoryTree(sku);
  }

  getDefaultLanguage(): string {
    return this.i18nService.getDefaultLanguage();
  }

  validateMarket(market: string): boolean {
    return this.i18nService.validateMarket(market);
  }

  validateLanguage(language: string): boolean {
    return this.i18nService.validateLanguage(language);
  }

  getLangParamData(langParam: string): string[] {
    return this.i18nService.getLangParamData(langParam);
  }

  buildLangParam(market: string, language: string): string {
    return this.i18nService.buildLangParam(market, language);
  }

  getCurrentLanguage(): string {
    return this.i18nService.getCurrentLanguage();
  }

  getCurrentParam(): string {
    return this.i18nService.getCurrentParam();
  }

  getLanguageRegex(url = false): RegExp {
    if (url) {
      return I18nService.languageUrlRegex;
    }
    return I18nService.languageParamRegex;
  }

  getTranslationByKey(key: string | Array<string>, params?: any): Observable<any> {
    return this.i18nService.getTranslationByKey(key, params);
  }

  getCurrentStoreAddress(): IStoreAddress {
    const localizedAddress = AppUtils.getCurrentStore()?.address[this.getCurrentLanguage()];
    return localizedAddress ?? AppUtils.getCurrentStore()?.address['default'];
  }

  setAlert(alert: any): void {
    this.store.dispatch(AppActions.setAlert({alert}));
  }

  clearAlert(): void {
    this.store.dispatch(AppActions.clearAlert());
  }

  getAlert(): Observable<any> {
    return this.store.select(AppSelectors.getAlert);
  }

  getTermsUrl(termsKey: string, lang?: string): any {
    if (!lang) {
      lang = this.getCurrentLanguage();
    }
    const store = AppUtils.getCurrentStore();
    return TermsUtils.getTermsUrl(termsKey, lang, store);
  }

  /**
   * Return true if store feature is available for current store within the built environment,
   * otherwise returns false.
   *
   * @param {EStoreFeatures} feature
   * @returns {boolean}
   */
  isFeatureAvailable(feature: EStoreFeatures): boolean {
    const currentStoreId = AppUtils.getCurrentStore().marketCode;
    const features = environment.features;
    return features[feature].includes(currentStoreId.toUpperCase());
  }

  /**
   * Return true if store feature is enabled via toggle,
   * otherwise return false
   *
   * @param {EFeatureToggles} featureToggle
   * @returns {Observable<boolean>}
   */
  isFeatureEnabled(featureToggle: EFeatureToggles): Observable<boolean> {
    const featureToggleKey = FEATURE_TOGGLE + featureToggle;
    return this.i18nService.getTranslationByKey(featureToggleKey).pipe(
      map(translationValue => translationValue.toString().toLowerCase() === 'true'),
    );
  }

  /**
   * Return array of strings separated with comma.
   * Prefix can be FEATURE_TOGGLE, FEATURE_CONFIG
   * Value is the key of the translation.
   *
   * @param {string} prefix
   * @param {string} value
   * @returns {Array<string>}
   */
  getArrayValues(prefix: string, value: string): Observable<Array<string>> {
    return this.i18nService.getTranslationByKey(prefix + value)
      .pipe(
        map(data => data.split(','))
      );
  }

  /**
   * Get dynamic form configuration for delivery details page. (Visible inputs on the current delivery detail page).
   *
   * @param {ECheckoutFlows} detailsPageType
   * @param {number} detailsPageNumber
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsFormConfiguration(detailsPageType: ECheckoutFlows, detailsPageNumber: number): Observable<string[]> {
    return this.i18nService.getTranslationByKey(
      `${FEATURE_CONFIG}${detailsPageType}_details_${detailsPageNumber}_page_visibility_of_inputs`,
    ).pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for delivery details page. (Required inputs).
   *
   * @param {ECheckoutFlows} detailsPageType
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsFormRequiredInputs(detailsPageType: ECheckoutFlows): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}${detailsPageType}_details_page_required_inputs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for delivery details page. (Read only inputs).
   *
   * @param {ECheckoutFlows} detailsPageType
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsFormReadOnlyInputs(detailsPageType: ECheckoutFlows): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}${detailsPageType}_details_page_read_only_inputs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for delivery details page. (Visible inputs for approver section).
   *
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsApproverFormConfiguration(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}delivery_details_page_approver_section.visibility_of_inputs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for delivery details page. (Required inputs for approver section).
   *
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsApproverFormRequiredInputs(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}delivery_details_page_approver_section.required_inputs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for delivery details page. (Read only inputs for approver section).
   *
   * @returns {Observable<string[]>}
   */
  getDynamicDeliveryDetailsApproverFormReadOnlyInputs(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}delivery_details_page_approver_section.read_only_inputs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form configuration for order review page. If there is no order summary section, then add it to the
   * end of the array.
   * @returns {Observable<string[]>}
   */
  getDynamicCheckoutSections(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_sections`)
      .pipe(map((data: string) => {
        const sections = this._parseStringToArray(data);
        if (!sections.includes('order_summary')) {
          sections.push('order_summary');
        }
        return sections;
      }));
  }

  getDynamicCheckoutSectionsRequiredFields(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_sections_required`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  getDynamicCheckoutAddresses(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_addresses`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  getDynamicCheckoutConsentCheckboxes(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_consent_checkboxes`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  getDynamicCheckoutConsentCheckboxesRequiredFields(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_consent_checkboxes_required`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  getDynamicCheckoutPaymentMethods$(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_payment_methods`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  getDynamicCheckoutPaymentMethodDefault$(): Observable<string> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}checkout_payment_method_default`);
  }

  /**
   * Get equipment identification key for current store.
   * @returns {Observable<string>}
   */
  getEquipmentIdentification$(): Observable<string> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}equipment_identification`);
  }

  /**
   * Get dynamic terms and conditions for delivery details page for Generate quote flow.
   * @returns {Observable<string[]>}
   */
  getDynamicGenerateQuoteConsentCheckboxes(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}generate_quote_flow_consent_checkboxes`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic terms and conditions for delivery details page for Generate quote flow.
   *
   * generate_quote_consent_checkboxes
   * create_order_consent_checkboxes
   * @returns {Observable<string[]>}
   */
  getDynamicConsentCheckboxes(consentsCheckboxesType: ETermsAndConsentsType): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}${consentsCheckboxesType}_consent_checkboxes`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic terms and conditions for delivery details page for Generate quote flow.
   *
   * generate_quote_consent_required_checkboxes
   * create_order_consent_required_checkboxes
   * @returns {Observable<string[]>}
   */
  getDynamicRequiredCheckboxes(consentsCheckboxesType: ETermsAndConsentsType): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}${consentsCheckboxesType}_consent_checkboxes_required`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get dynamic form departments options for delivery details page.
   *
   * @returns {Observable<IBaseConfig[]>}
   */
  getDynamicDeliveryDetailsFormDepartments(): Observable<IBaseConfig[]> {
    return this.i18nService.getTranslationByKey(
      `${FEATURE_CONFIG}delivery_details_page_departments_${this.i18nService.getCurrentLanguage()}`,
    ).pipe(
      map((data: string) => this._parseStringToArray(data)),
      map((array: string[]) => array.map((department: string) => ({
        name: department,
        value: department,
      }))),
    );
  }

  /**
   * Get dynamic form delivery times options for delivery details page.
   *
   * @returns {Observable<IBaseConfig[]>}
   */
  getDynamicDeliveryDetailsFormDeliveryTimes(): Observable<IBaseConfig[]> {
    return this.i18nService.getTranslationByKey(
      `${FEATURE_CONFIG}delivery_details_page_delivery_times_${this.i18nService.getCurrentLanguage()}`,
    ).pipe(
      map((data: string) => this._parseStringToArray(data)),
      map((array: string[]) => array.map((department: string) => ({
        name: department,
        value: department,
      }))),
    );
  }

  /**
   * Get dynamic fields for order thank you page.
   *
   * @returns {Observable<string[]>}
   */
  getDynamicOrderThankYouPageFields(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}order_thank_you_page_fields`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get tabs, which should be shown on purchase activity page.
   * @returns {Observable<string[]>}
   */
  getDynamicPurchaseActivityTabs(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}purchase_activity_tabs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Get previous orders tabs, which should be shown on purchase activity page.
   * @returns {Observable<string[]>}
   */
  getDynamicPurchaseActivityPreviousOrdersTabs(): Observable<string[]> {
    return this.i18nService.getTranslationByKey(`${FEATURE_CONFIG}purchase_activity_previous_orders_tabs`)
      .pipe(map((data: string) => this._parseStringToArray(data)));
  }

  /**
   * Split string data (configurations) to array of strings.
   *
   * @param {string} data
   * @returns {string[]}
   * @private
   */
  private _parseStringToArray(data: string): string[] {
    return data ? data.split(',') : [];
  }

  /**
   * setTimout function is used, because delay is needed so the notification is
   * not closed with the same click, which opened it
   *
   * setTimeout is used as a "zero-delay timeout/deferred function" technique
   *
   * @param {INotification} notification
   */
  appendNotification(notification: INotification): void {
    setTimeout(() => {
      this.store.dispatch(AppActions.appendNotification({notification}));
    });
  }

  closeNotification(notificationId: number) {
    this.store.dispatch(AppActions.closeNotification({notificationId}));
  }

  clearNotifications(): void {
    this.store.dispatch(AppActions.clearNotifications());
  }

  getNotification(): Observable<any> {
    return this.store.select(AppSelectors.getNotification);
  }

  redirectToLastPage(): void {
    this.store.dispatch(AppActions.redirectToLastPage());
  }

  selectFullPageLoaderList(): Observable<any> {
    return this.store.select(AppSelectors.selectFullPageLoaderList);
  }

  /**
   * Push EFullPageLoaderListOptions.NAVIGATION name to the fullPageLoaderList
   */
  navBarLoaded(): void {
    this.store.dispatch(AppActions.navBarLoaded({name: EFullPageLoaderListOptions.NAVIGATION}));
  }

  /**
   * Push EFullPageLoaderListOptions.HOMEPAGE_BANNER name to the fullPageLoaderList
   */
  homePageBannerLoaded(): void {
    this.store.dispatch(AppActions.homePageBannerLoaded({name: EFullPageLoaderListOptions.HOMEPAGE_BANNER}));
  }
}
