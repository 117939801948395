<div class="advanced-search--body">
  <div class="fields-container">
    <div class="columns">
      <div class="column">
        <app-select-box
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.soldToAddress"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-soldToAddress'}"
            [options]="getOptionsForSelectBox('soldToAddress')"
            [disabled]="isSelectBoxEmpty('soldToAddress')"
            [inputName]="'soldToAddress'"
            label="{{ 'purchase-activity.sold-to-address' | translate }}"
            (selected)="setFormValue($event)">
        </app-select-box>
        <app-select-box
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.shipToAddress"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-shipToAddress'}"
            [options]="getOptionsForSelectBox('shipToAddress')"
            [disabled]="isSelectBoxEmpty('shipToAddress')"
            [inputName]="'shipToAddress'"
            label="{{ 'purchase-activity.ship-to-address' | translate }}"
            (selected)="setFormValue($event)">
        </app-select-box>
        <div class="datepickers">
          <app-datepicker
              class="datepicker"
              [form]="advancedFiltersForm"
              [modelValue]="advancedFiltersForm.value.orderDateFrom"
              [dateFormat]="'m/d/Y'"
              [inputName]="'orderDateFrom'"
              label="{{ 'purchase-activity.order-date-from' | translate }}"
              (selected)="setFormValue($event)"
              (resetInput)="setFormValue($event)">
          </app-datepicker>
          <app-datepicker
              class="datepicker"
              [form]="advancedFiltersForm"
              [modelValue]="advancedFiltersForm.value.orderDateTo"
              [dateFormat]="'m/d/Y'"
              [inputName]="'orderDateTo'"
              label="{{ 'purchase-activity.order-date-to' | translate }}"
              (selected)="setFormValue($event)"
              (resetInput)="setFormValue($event)">
          </app-datepicker>
        </div>
      </div>
      <div class="column">
        <app-select-box
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.orderStatus"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-orderStatus'}"
            [options]="getOptionsForSelectBox('orderStatus')"
            [disabled]="isSelectBoxEmpty('orderStatus')"
            [inputName]="'orderStatus'"
            label="{{ 'purchase-activity.order-status' | translate }}"
            (selected)="setFormValue($event)">
        </app-select-box>
        <app-select-box
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.flNumber"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-flNumber'}"
            [options]="getOptionsForSelectBox('flNumber')"
            [disabled]="isSelectBoxEmpty('flNumber')"
            [inputName]="'flNumber'"
            label="{{ 'purchase-activity.equipment-id-number' | translate:
            {equipmentIdentification: 'purchase-activity.' + (equipmentIdentification$ | async ) | translate} }}"
            (selected)="setFormValue($event)">
        </app-select-box>
        <app-textfield
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.materialNumber"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-materialNumber'}"
            [inputName]="'materialNumber'"
            label="{{ 'purchase-activity.material-number' | translate }}">
        </app-textfield>
      </div>
      <div class="column">
        <app-textfield
            [form]="advancedFiltersForm"
            [modelValue]="advancedFiltersForm.value.orderNumber"
            [cssStyles]="{wrapper: 'textfield-with-prefix textfield-orderNumber'}"
            [inputName]="'orderNumber'"
            label="{{ 'purchase-activity.order-number' | translate }}">
        </app-textfield>
        @if ((poNumberVisibilityByTabs$ | async).includes(currentTab)) {
          <app-textfield
              [form]="advancedFiltersForm"
              [modelValue]="advancedFiltersForm.value.purchaseOrder"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-purchaseOrder'}"
              [inputName]="'purchaseOrder'"
              label="{{ 'purchase-activity.purchase-order' | translate }}">
          </app-textfield>
        }
        @if (isSapStore) {
          <app-textfield
              [form]="advancedFiltersForm"
              [modelValue]="advancedFiltersForm.value.rmaNumber"
              [cssStyles]="{wrapper: 'textfield-with-prefix textfield-rmaNumber'}"
              [inputName]="'rmaNumber'"
              label="{{ 'purchase-activity.rma-number' | translate }}">
          </app-textfield>
        }
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="button button--standard"
            (click)="resetFilters()"
            [attr.data-test]="'button-reset'">
      {{ 'purchase-activity.reset-button' | translate }}
    </button>
    <button class="button button--standard"
            (click)="clearAndCloseAdvancedFilter()"
            [attr.data-test]="'button-cancel'">
      {{ 'purchase-activity.cancel-button' | translate }}
    </button>
    <button class="button button--primary"
            (click)="applySelectedFiltersOptions()"
            [attr.data-test]="'button-apply'">
      {{ 'purchase-activity.apply-button' | translate }}
    </button>
  </div>
</div>
