import { Pipe, PipeTransform } from '@angular/core';
import { FormatDatePipe } from './format-date.pipe';
import { PurchaseActivityTabs } from '../../configurations/common';

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFieldPipe implements PipeTransform {

  constructor(private formatDatePipe: FormatDatePipe) {
  }

  static checkInside(item: any, term: string, toCompare: string, excludes: any, formatDatePipe: FormatDatePipe, currentTab?: string): boolean {

    if (typeof item === 'string' && item.toString().toLowerCase().includes(toCompare)) {
      return true;
    }

    for (const property in item) {
      if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
        continue;
      }
      if (typeof item[property] === 'object') {
        if (this.checkInside(item[property], term, toCompare, excludes, formatDatePipe, currentTab)) {
          return true;
        }
      } else {
        let valueToCompare = item[property].toString().toLowerCase();

        if (!isNaN(Date.parse(item[property]))) {
          valueToCompare = formatDatePipe.transform(item[property]).toLowerCase();
        }

        // pending orders have a different id format e.g. a-z0-9-a-z0-9-a-z0-9-a-z0-9,
        // but into filter goes id from accordion header which is only first part of id with #
        if (currentTab === PurchaseActivityTabs.PENDING_ORDERS && property === 'id' && `#${item?.id}`.includes(toCompare)) {
          return true;
        }

        if (valueToCompare.includes(toCompare)) {
          return true;
        }
      }

    }
    return false;
  }

  static filter(
    items: Array<{[key: string]: any}>,
    term: string,
    excludes: any,
    formatDatePipe: FormatDatePipe,
    currentTab: string): Array<{[key: string]: any}> {

    const toCompare = term.toLowerCase();

    return items.filter((item) => {
      return this.checkInside(item, term, toCompare, excludes, formatDatePipe, currentTab);
    });
  }

  /**
   * Filters the items by the given term.
   * @param {any} items
   * @param {string} term
   * @param {any[]} excludes
   * @param {string} currentTab
   * @returns {any}
   */
  transform(items: any, term: string, excludes: any = [], currentTab?: string): any {
    if (!term || !items) {
      return items;
    }

    return SearchFieldPipe.filter(items, term, excludes, this.formatDatePipe, currentTab);
  }
}
