import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { OrdersFacade } from '../facades/orders.facade';
import {
  IArchiveOrderResponse,
  IHybrisOrderItem,
  IOrderData,
  ICartsApprovalResponse,
  IQuoteHistoryResponse,
  IOrdersResponse,
} from '../models/order.models';
import { CustomerActions, PurchaseActivityActions } from '../actions';
import { EOrderWorkflows, EStoreFeatures } from '../configurations/common';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { IRfqHistory } from '../models/rfq.models';
import { CartUtils } from '../utils/cart.utils';
import { TypedAction } from '@ngrx/store/src/models';
import { AppUtils } from '../utils/app.utils';

@Injectable({
  providedIn: 'root',
})
export class PurchaseActivityEffects {
  constructor(
    private actions$: Actions,
    private ordersFacade: OrdersFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  initPurchaseHistoryAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PurchaseActivityActions.initPurchaseActivity,CustomerActions.CompanyUsersActionSuccess),

      concatMap(() => {
        const actions: TypedAction<any>[] = [
          PurchaseActivityActions.loadOrdersInPendingOrders(),
          PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequests()
        ];

        actions.push(PurchaseActivityActions.loadArchiveInPreviousOrders());
        actions.push(PurchaseActivityActions.initOrdersLoadingInPreviousOrders(0));
        return actions;
      }),
    );
  });

  loadOrdersInPreviousOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.initOrdersLoadingInPreviousOrders),
      concatMap((action) => {
        const pagination = AppUtils.getCurrentStore().paginationSettingsPerFeature;
        const previousSapOrdersLimit = pagination.sparePartOrders.limitPerPage;
        const previousOrdersLimit = pagination.orders.limitPerPage;
        const actions: TypedAction<any>[] = [];
        if (this.configurationFacade.isFeatureAvailable(EStoreFeatures.SPARE_PARTS)) {
          actions.push(PurchaseActivityActions.loadSapOrdersInPreviousOrders(action.offset, previousSapOrdersLimit));
        } else {
          actions.push(PurchaseActivityActions.loadOrdersInPreviousOrders(action.offset, previousOrdersLimit));
        }
        return actions;
      })
    )
  );

  loadOrdersHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.loadOrdersInPreviousOrders),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          action.offset, action.limit, {includeApproverOrders: true},
        ).pipe(
          map((orders: IOrdersResponse) => {
            const mappedOrders = {
              ...orders,
              data: this._mapOrders(orders.data),
            }
            return PurchaseActivityActions.loadOrdersInPreviousOrdersSuccess({
              orders: mappedOrders,
            });
          }),
          catchError((error) => of(PurchaseActivityActions.loadOrdersInPreviousOrdersFailure({resError: error}))),
        ),
      ),
    ));

  loadOrdersHistoryWithSapDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.loadSapOrdersInPreviousOrders),
      mergeMap(
        (action) => this.ordersFacade.getOrders(
          action.offset, action.limit,
          {
            includeApproverOrders: true,
            workflow: EOrderWorkflows.SAP_ORDER,
          },
        ).pipe(
          map((orders: IOrdersResponse) => {
              const mappedOrders = {
                ...orders,
                data: this._mapOrders(orders.data),
              }
              return PurchaseActivityActions.loadSapOrdersInPreviousOrdersSuccess({
                ordersWithSapDetails: mappedOrders
              });
            }
          ),
          catchError((error) => of(PurchaseActivityActions.loadSapOrdersInPreviousOrdersFailure({resError: error}))),
        ),
      ),
    )
  );

  loadArchiveInPreviousOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.loadArchiveInPreviousOrders),
      mergeMap(() => this.ordersFacade.getArchiveOrders()
        .pipe(
          map((orders: IArchiveOrderResponse) => {
            const mappedOrders: IOrdersResponse = {
              ...orders,
              data: this._mapOrders(orders.data),
            }
            return PurchaseActivityActions.loadArchiveInPreviousOrdersSuccess({
              archiveOrders: mappedOrders
            });
          }),
          catchError((error) => of(PurchaseActivityActions.loadArchiveInPreviousOrdersFailure({resError: error}))),
        )),
    )
  );

  loadOrdersInPendingOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.loadOrdersInPendingOrders),
      mergeMap(
        () => this.ordersFacade.getCartsApproval()
          .pipe(
            map((pendingOrders: ICartsApprovalResponse) => {
              const mappedPendingOrders: IOrdersResponse = {
                ...pendingOrders,
                data: this._mapOrders(pendingOrders.data),
              };
              return PurchaseActivityActions.loadOrdersInPendingOrdersSuccess({
                pendingOrders: mappedPendingOrders
              });
            }),
            catchError((error) => of(PurchaseActivityActions.loadOrdersInPendingOrdersFailure({resError: error}))),
          ),
      ),
    )
  );

  loadQuotesAndRequestInPreviousQuotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequests),
      mergeMap(
        () => this.ordersFacade.getPreviousAndRequestQuotes()
          .pipe(
            map((data: IQuoteHistoryResponse) => {
              const { previousQuotes, previousRequests } = this._filterQuotes(data);

              return PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequestsSuccess({
                previousQuotes: this._mapOrders(previousQuotes),
                previousRequests: this._mapOrders(previousRequests),
              });
            }),
            catchError((error) => of(PurchaseActivityActions.loadQuotesAndRequestsInPreviousQuotesAndRequestsFailure({ resError: error }))),
          ),
      ),
    )
  );


  /**
   * TODO: After creating mapper on BE these functions can be removed.
   * There is no need to set return types for following functions.
   */

  /**
   * Maps the address attributes from the order data based on the provided address type and fallback type.
   * @param {string} addressType - The type of address to map (e.g., 'shipping', 'billing', 'soldTo').
   * @param {string} fallbackType - The fallback type to use for missing attributes (e.g., 'shipTo', 'billTo', 'soldTo').
   * @param {IOrderData} order - The order data containing the address attributes.
   * @returns {any} - The mapped address attributes.
   */
  private _mapAddressAttributes(addressType: string, fallbackType: string, order: IOrderData): any {
    return {
      address1: order.attributes?.[`${fallbackType}Address`],
      company: order.attributes?.[`${addressType}Address`]?.company ?? order.attributes?.[`${fallbackType}Name`],
      sapId: order.attributes?.[`${addressType}Address`]?.sapId ?? order.attributes?.[`${fallbackType}Number`],
      zipCode: order.attributes?.[`${addressType}Address`]?.zipCode ?? order.attributes?.[`${fallbackType}PostalOffice`],
      iso2Code: order.attributes?.[`${addressType}Address`]?.iso2Code ?? order.attributes?.[`${fallbackType}State`],
      city: order.attributes?.[`${addressType}Address`]?.city ?? order.attributes?.[`${fallbackType}Town`],
      email: order.attributes?.[`${addressType}Address`]?.email ?? order.attributes?.userEmail,
      firstName: order.attributes?.[`${addressType}Address`]?.firstName ?? order.attributes?.userName.split(' ')[0],
      lastName: order.attributes?.[`${addressType}Address`]?.lastName ?? order.attributes?.userName.split(' ').slice(1).join(' '),
    };
  }

  /**
   * Maps other order interfaces to the common one, so that they can be used in the same way (same data structure)
   * @param data
   * @returns {any}
   * @private
   */
  private _mapOrders(data: any): any {
    const orders = [];
    data.forEach((order: IOrderData) => {
      if (!order.attributes.userName) {
        order.attributes.userName = order.attributes.customer.firstName + ' ' + order.attributes.customer.lastName;
      }
      let items = [];
      if (order.attributes?.items) {
        items = order.attributes?.items.map((item: any) => ({
          ...item,
          unitPrice: item.unitPrice ?? item.itemPrice,
          sumPrice: item.sumPrice ?? this._calculateSumPrice([item]),
          name: item.name ?? item.productName,
          materialNumber: item.materialNumber ?? item.productNumber
        }));
      } else if (order.attributes?.shownVersion?.cart?.items) {
        if (Array.isArray(order.attributes?.shownVersion?.cart?.items)) {
          items = order.attributes?.shownVersion?.cart?.items.map((item: any) => (
            {
              ...item,
              unitPrice: item?.calculations?.unitPrice ?? 0,
              sumPrice: item?.calculations?.sumPrice ?? 0,
              quantity: item?.quantity ?? 1,
            }));
        }
      }

      let archiveSoldToAddress, archiveBillToAddress, archiveShippingAddress, archivePointOfContact;

      if (order.type === 'customer-orders-history') {
        archiveSoldToAddress = this._mapAddressAttributes('soldTo', 'soldTo', order);
        archiveBillToAddress = this._mapAddressAttributes('billing', 'billTo', order);
        archiveShippingAddress = this._mapAddressAttributes('shipping', 'shipTo', order);


        archivePointOfContact = {
          email: order.attributes?.pointOfContact?.email ?? order.attributes?.userEmail,
          firstName: order.attributes?.pointOfContact?.firstName ?? order.attributes?.userName.split(' ')[0],
          lastName: order.attributes?.pointOfContact?.lastName ?? order.attributes?.userName.split(' ').slice(1).join(' '),
          phone: order.attributes?.pointOfContact?.phone ?? order.attributes?.phoneNumber,
        }
      }

      orders.push(
        {
          ...order,
          attributes: {
            ...order.attributes,
            userName: order.attributes?.userName ?? (order.attributes?.customer?.firstName + ' ' + order.attributes?.customer?.lastName),
            sumPrice: (order.attributes?.totals?.subtotal ?? this._mapSumPrice(order)),
            equipment: order.attributes?.equipment ?? order.attributes?.systemDetails?.siemensEquipmentId,
            equipmentName: order.attributes?.systemDetails?.nameEnUs,
            createdAt: order.attributes?.createdAt ?? order.attributes?.orderCreationDate,
            stateDisplayName: order.attributes?.stateDisplayName ?? order.attributes?.orderStatus ?? order.attributes?.status ?? order.attributes?.approverDetails?.status,
            soldToAddress: (typeof (order.attributes?.soldToAddress) !== 'string') ? order.attributes?.soldToAddress : archiveSoldToAddress,
            billingAddress: order.attributes?.billingAddress ?? archiveBillToAddress,
            shippingAddress: order.attributes?.shippingAddress ?? archiveShippingAddress,
            pointOfContact: order.attributes?.pointOfContact ?? archivePointOfContact,
            items: items,
          }
        },
      );
    });

    return orders;
  }

  /**
   * Put the RFQ history items in the array if they are not
   * @param {IRfqHistory} data
   * @returns {IRfqHistory}
   * @private
   */
  private _normalizeItems(data: IRfqHistory): IRfqHistory {
    data.data.forEach(quote => {
      if (!Array.isArray(quote.attributes.shownVersion?.cart?.items)) {
        quote.attributes.shownVersion.cart.items = [quote.attributes.shownVersion?.cart?.items];
      }
    });
    return data;
  }

  /**
   * Filters the quotes into previous quotes and previous requests
   * @param {IRfqHistory} data
   * @returns {{previousQuotes: any[], previousRequests: any[]}}
   * @private
   */
  private _filterQuotes(data: IRfqHistory): { previousQuotes: any[], previousRequests: any[] } {
    const previousQuotes = [];
    const previousRequests = [];

    data = this._normalizeItems(data);
    data.data.forEach(quote => {
      if (CartUtils.isSetPricesForAllItems(quote.attributes.shownVersion?.cart?.items)) {
        previousQuotes.push(quote);
      } else {
        previousRequests.push(quote);
      }
    });

    return { previousQuotes, previousRequests };
  }

  /**
   * Calculates the sum price of the given items from different formats
   * @param items
   * @returns {number}
   * @private
   */
  private _calculateSumPrice(items: IHybrisOrderItem[]): number {
    let sum = 0;

    items.forEach((item: IHybrisOrderItem) => {
      const itemPrice = item?.itemPrice ?? item?.calculations?.unitPrice ?? 0;
      const quantity = item?.quantity ?? 0;
      sum += itemPrice * quantity;
    });

    return sum;
  }

  /**
   * Maps the sum price of the order from different formats
   * @param order
   * @returns {number}
   * @private
   */
  private _mapSumPrice(order: any): number {
    if ( order.attributes?.shownVersion?.cart?.items) {
      return this._calculateSumPrice(order.attributes?.shownVersion?.cart?.items);
    } else {
      return this._calculateSumPrice(order.attributes?.items) ?? 0;
    }
  }
}
