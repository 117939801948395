<section class="history-list-actions">
  <div class="right-aligned-components">
    @if (currentTab !== PurchaseActivityTabs.PENDING_ORDERS) {
      <button class="button button--link orders-list-download desktop"
              [attr.data-test]="'button-downloadOrderList'"
              [disabled]="isFilteredListOfOrdersEmpty()"
              (click)="downloadOrderListExcel()">
        @if (!isExcelGenerationInProgress) {
          <span class="icon-download"></span>
          <span [attr.data-test]="'desc-downloadList'" class="text">
          {{ 'purchase-activity.download-' + currentTab + '-list' | translate }}
        </span>
        } @else {
          <div class="btn-spinner">
            <div class="loading-animation"></div>
          </div>
        }
      </button>
    }
    @if (isMyOrdersFilterAvailable$ | async) {
      <div class="show-only-my-orders">
        <input id="show-mine-checkbox"
               type="checkbox"
               name="show-mine-checkbox"
               class="checkbox"
               [disabled]="isLoadingOrders"
               [checked]="showOnlyMyOrders"
               (change)="toggleShowingOnlyMyOrders()"
               [attr.data-test]="'checkbox-showOnlyMine'"/>
        <label [attr.data-test]="'label-showMineOrders'" for="show-mine-checkbox">
          {{ 'purchase-activity.show-only-my-' + currentTab | translate }}
        </label>
      </div>
    }
    <div class="js-search-field search-field textfield textfield--with-prefix">
      <span class="textfield__prefix icon-search"></span>
      <input id="search"
             name="search"
             type="search"
             class="textfield__input search-field__input"
             spellcheck="false"
             autocomplete="off"
             placeholder="{{ 'purchase-activity.search' | translate }}"
             [disabled]="isLoadingOrders"
             [(ngModel)]="quickSearch"
             [attr.data-test]="'input-search'"/>
    </div>
  </div>
  <div class="advanced-search-button-container">
    <button class="button"
            [ngClass]="isAdvancedSearchOpened ? 'icon-arrow-top' : 'icon-arrow-down'"
            [attr.data-test]="'button-advancedSearch'"
            (click)="isAdvancedSearchOpened = !isAdvancedSearchOpened">
      {{ 'purchase-activity.advanced-search' | translate }}
    </button>
  </div>
</section>
<section class="advanced-search-container" [class]="{'is-visible': isAdvancedSearchOpened}"
         [attr.data-test]="'button-advancedSearch'">
  <app-advanced-search
      [availableOrders]="orders['itemsPerPage'][orders['pagination']['currentPage']]"
      [triggerClearAllFilters$]="triggerClearAllFilters$"
      [currentTab]="currentTab"
      (closeAdvancedFiltersSection)="closeAdvancedSearch()"
      (applyAdvancedFilters)="applyAdvancedFilters($event)"
  />
</section>
<section class="history-list">
  <div class="orders-table-header">
    <div [attr.data-test]="'title-orderNumber'" class="table-column">{{ 'purchase-activity.order-no' | translate }}
    </div>
    <div [attr.data-test]="'title-orderDate'" class="table-column">{{ 'purchase-activity.date' | translate }}</div>
    @if (currentTab === PurchaseActivityTabs.PREVIOUS_ORDERS) {
      <div [attr.data-test]="'title-orderEquipment'"
           class="table-column">{{ 'purchase-activity.equipment' | translate }}
      </div>
    }
    @if (currentTab === PurchaseActivityTabs.PENDING_ORDERS) {
      <div [attr.data-test]="'title-approveBy'" class="table-column">{{ 'purchase-activity.approve-by' | translate }}
      </div>
    }
    <div [attr.data-test]="'title-orderStatus'"
         class="table-column">{{ 'purchase-activity.order-status' | translate }}
    </div>
    <div [attr.data-test]="'title-orderTotal'" class="table-column">{{ 'purchase-activity.total' | translate }}</div>
  </div>
  @for (order of orders['itemsPerPage'][orders['pagination']['currentPage']]
      | advancedFiltering: appliedAdvancedFilters:currentTab | searchFilter: quickSearch:[]:currentTab;
      track order) {
    <app-history-item-detail
        [order]="order"
        [currentTab]="currentTab"
        [currentSubTab]="currentSubTab"
        (openAddItemToCartModal)="isAddItemToCartModalVisible = true"
        (setItemsForAddingToCart)="itemsToAdd = $event"
        (setFlForReorder)="flNumberOfReorder = $event"
        (setOrderId)="orderId = $event"
    />
  }
  @if (isFilteredListOfOrdersEmpty()) {
    <div [attr.data-test]="'desc-noFilteredResults'" class="no-results">
      {{
        !isAppliedFilter() ? ('purchase-activity.' + currentTab + '-no-results' | translate) :
            ('purchase-activity.' + currentTab + '-no-filtered-results' | translate)
      }}
    </div>
  }
</section>
<app-add-item-modal
    [isModalInitialized]="isAddItemToCartModalVisible"
    [showModal]="isAddItemToCartModalVisible"
    [items]="itemsToAdd"
    [currentCartId]="currentCartId$ | async"
    [isReorderWorkflowSelected]="true"
    [flNumberOfReorder]="flNumberOfReorder"
    [orderId]="orderId"
    [isSapP40Enable]="true"
    (closeModal)="isAddItemToCartModalVisible = false"
    (isModalInProgress)="isModalInProgress = $event">
</app-add-item-modal>
