import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, skipWhile, Subject } from 'rxjs';
import { CustomerFacade } from '../../../facades/customer.facade';
import { take, takeUntil } from 'rxjs/operators';
import { ICustomerAddressPayloadData } from '../../../models/customer.models';
import { AppUtils } from '../../../utils/app.utils';
import { InstallBaseFacade } from '../../../facades/install-base.facade';
import { EFeatureToggles } from '../../../configurations/common';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-delivery-addresses',
  templateUrl: './delivery-addresses.component.html',
  styleUrls: ['./delivery-addresses.component.scss'],
})
export class DeliveryAddressesComponent implements OnInit, OnDestroy {
  @Input() customerId: string;
  @Input() businessUnitName: string;
  @Input() businessUnitNumber: string;

  isSapStore: boolean = AppUtils.isSapStore();
  customerPreferences: any;

  addresses: any[];
  addressesLoading: boolean = true;
  originalAddresses: any[];

  onlyCustomerAddresses: boolean = false;
  searchAddress: string;
  viewAllAddresses: boolean = false;

  showModalAddAddress: boolean = false;
  addAddressInProgress: boolean = false;

  updateCustomerAddressesInProgress: boolean = false;
  updatePreferredAddressInProgress: boolean = false;

  isSdmIntegrationEnabled: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private installBaseFacade: InstallBaseFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectAddresses();
    this.customerFacade.selectUpdateCustomerAddressesInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(updateCustomerAddressesInProgress => {
      this.updateCustomerAddressesInProgress = updateCustomerAddressesInProgress;

      if (!this.updateCustomerAddressesInProgress && this.addAddressInProgress) {
        this.addAddressInProgress = false;
      }
    });
    this.selectCustomerPreferences();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Get all addresses (addresses of different types from different sources).
   *
   * Different types of addresses:
   * - SAP related addresses (only SAP stores)
   * - tpF addresses (only non-SAP stores)
   * - customer addresses (all stores)
   * - business addresses (only when SDM integration disabled)
   */
  selectAddresses(): void {
    this.addressesLoading = true;
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SDM_INTEGRATION).pipe(take(1)).subscribe(value => {
      this.isSdmIntegrationEnabled = value;
      combineLatest([
        this.isSapStore
          ? this.customerFacade.selectCustomerShipToAddresses()
          : this.installBaseFacade.selectInstalledBaseAddresses(),
        this.customerFacade.selectCustomerAddresses(),
        !this.isSdmIntegrationEnabled ? this.customerFacade.selectBusinessAddress() : of([]),
      ]).pipe(
        takeUntil(this.unsubscribe$),
        skipWhile(([shipToAddresses, customerAddresses, businessAddresses]) => {
          return !shipToAddresses || !customerAddresses || (!this.isSdmIntegrationEnabled && !businessAddresses);
        }),
      ).subscribe({
        next: ([shipToAddresses, customerAddresses, businessAddresses]) => {
          this.originalAddresses = shipToAddresses.concat(customerAddresses);

          if (!this.isSdmIntegrationEnabled) {
            this.originalAddresses = businessAddresses.concat(this.originalAddresses);
          }

          this.sortAddresses();
          this.addressesLoading = false;
        },
      });
    });
  }

  /**
   * Set order and sorting for different types of addresses.
   *
   * Ordering:
   * - SDM integration enabled:
   * -- 1. SAP related addresses
   * -- 2. customer addresses
   *
   * - SDM integration disabled:
   * -- 1. business addresses
   * -- 2. tpF addresses
   * -- 3. customer addresses
   *
   * - if address is set as preferred address, it should be on the first position
   * - if "only customer addresses" checkbox is filled, non-customer addresses should be filtered out
   *
   * Sorting:
   * - non-customer addresses are sorted by sapId
   * - customer addresses are sorted by id (as manually created addresses do not have sapId)
   */
  sortAddresses(): void {
    const defaultAddresses = this.originalAddresses.filter(address => !address?.isCustom).sort((address1, address2) => {
      if (!address1?.sapId) {
        return -1;
      }

      if (!address2?.sapId) {
        return 1;
      }

      return address1.sapId - address2.sapId;
    });

    const customerAddresses = this.originalAddresses.filter(address => address?.isCustom).sort((address1, address2) => {
      return address1?.name?.localeCompare(address2?.name);
    });

    this.originalAddresses = defaultAddresses.concat(customerAddresses);

    if (this.customerPreferences?.preferredShipToId) {
      const preferredShipToAddress = this.originalAddresses.find(address => {
        return address?.sapId === this.customerPreferences?.preferredShipToId ||
          address?.id === this.customerPreferences?.preferredShipToId;
      });

      if (preferredShipToAddress) {
        this.originalAddresses = this.originalAddresses.filter(address => {
          return address?.sapId !== this.customerPreferences?.preferredShipToId &&
            address?.id !== this.customerPreferences?.preferredShipToId;
        });

        this.originalAddresses.unshift(preferredShipToAddress);
      }
    }

    this.addresses = this.originalAddresses;

    if (this.onlyCustomerAddresses) {
      this.addresses = this.originalAddresses.filter(address => address?.isCustom);
    }
  }

  /**
   * Method for displaying all addresses or only customer addresses.
   */
  selectOnlyCustomerAddresses(): void {
    this.onlyCustomerAddresses = !this.onlyCustomerAddresses;
    this.sortAddresses();
  }

  /**
   * Add new customer address.
   *
   * @param {ICustomerAddressPayloadData} addressData
   */
  addAddress(addressData: ICustomerAddressPayloadData): void {
    this.customerFacade.beginAddCustomerAddressAction(this.customerId, addressData);
    this.addAddressInProgress = true;
    this.showModalAddAddress = false;
  }

  /**
   * Method for displaying "adding new address" modal.
   */
  showAddAddressModal(): void {
    if (!this.updateCustomerAddressesInProgress && !this.updatePreferredAddressInProgress) {
      this.showModalAddAddress = true;
    }
  }

  /**
   * Method for selecting customer preferences.
   */
  private selectCustomerPreferences(): void {
    this.customerFacade.selectCustomerPreferences().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(customerPreferences => {
      if (customerPreferences) {
        this.customerPreferences = customerPreferences;
      }
    });
  }
}
