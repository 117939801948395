<div class="container request-tracking-history">
  <div [appLoadingAnimation]="!isLoaded" class="app-loading">
    <ng-container *ngIf="quoteDetails">
      <div class="row">
        <div class="col-12">
          <h3 [attr.data-test]="'title-requestDetails'">
            {{ getTranslationGroup() + '.page-title' | translate }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-9">
          <h6 class="request-status" [attr.data-test]="'title-requestStatus'">
            {{ getTranslationGroup() + '.status-of' | translate }} {{ quoteDetails.id }}
          </h6>
          <div class="request-status__desc">
            <span [ngClass]="statusIcon()"></span>
            <span [attr.data-test]="'desc-requestStatus'">
              {{ getTranslationGroup() + '.status-bar-request-' + quoteDetails.attributes.status + '-title'
                | translate: {number: quoteDetails.id} }}
            </span>
          </div>
        </div>
        <div class="col-12 col-lg-3 display-flex flex-column">
          <div class="back-to-cart__wrapper">
            <button type="button" class="back-to-cart" (click)="backToOrders()" [attr.data-test]="'button-backToRequests'">
              <span class="icon-arrow-left"></span>
              @if (isHarmonizedPuchaseActivityEnabled$ | async) {
                <span [attr.data-test]="'desc-backToPurchaseActivity'">
                {{ 'order-details.back-to-purchase-activity' | translate }}
                </span>
              } @else {
                <span [attr.data-test]="'desc-backToRequests'">
                {{ getTranslationGroup() + '.back-btn' | translate }}
                </span>
              }
            </button>
          </div>
          <div class="cancel-button__wrapper" *ngIf="!isGeneratedQuoteWorkflow()">
            <button
                type="button"
                class="cancel-button"
                [disabled]="cancelButtonDisabled()"
                (click)="cancelQuoteRequestModal()"
                [attr.data-test]="'button-cancelRequest'">
              {{ 'request-tracking.cancel-btn' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <app-steps-bar
              [currentStatus]="quoteDetails.attributes.status"
              [currentDesc]="quoteDetails.attributes.quoteNumber"
              [scenarioType]="isGeneratedQuoteWorkflow() ? generatedQuoteType : quoteRequestType"
              [comment]="quoteDetails.attributes.statusComment">
          </app-steps-bar>
        </div>
      </div>
      <div class="row">
        <div class="col-12 request-items-styling">
          <h4 [attr.data-test]="'title-requestItems'">
            {{ getTranslationGroup() + '.your-items' | translate }}
          </h4>
        </div>
      </div>
      <div class="row justify-content-between table-header-colors">
        <div class="col-12 col-lg-3">
          <div class="col-12 col-lg-10">{{ getTranslationGroup() + '.col-id' | translate }}</div>
          <div class="col-12 col-lg-10">
            <strong [attr.data-test]="'desc-requestId'">
              {{ quoteDetails.id }}
            </strong>
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <div class="col-12 col-lg-10" [attr.data-test]="'label-requestDate'">
            {{ getTranslationGroup() + '.col-date' | translate }}
          </div>
          <div class="col-12 col-lg-10">
            <strong [attr.data-test]="'desc-requestDate'">
              {{ quoteDetails.attributes.createdAt | formatDate }}
            </strong>
          </div>
        </div>
        <div class="col-12 col-lg-2 total-qty">
          <div class="col-12" [attr.data-test]="'label-requestTotalQuantity'">
            {{ 'order-tracking-history.total-quantity' | translate }}
          </div>
          <div class="col-12">
            <strong [attr.data-test]="'desc-requestTotalQuantity'">
              {{ totalQuantity }} {{ (totalQuantity === 1 ? 'order-tracking-history.item' : 'order-tracking-history.items') | translate }}
            </strong>
          </div>
        </div>
        <div class="col-12 col-lg-4 total-price" *ngIf="isGeneratedQuoteWorkflow()">
          <div class="col-12 col-lg-10" [attr.data-test]="'label-requestTotalSum'">
            {{ 'order-tracking-history.total-sum' | translate }}
          </div>
          <div class="col-12 col-lg-10">
            <strong [attr.data-test]="'desc-requestTotalSum'">
              {{ quoteDetails.attributes.shownVersion.cart.totals.grandTotal | currency: quoteDetails.attributes.shownVersion.cart.currency }}
            </strong>
            <p>
              @if (AppUtils.isStoreActive(EStoreTypes.IN);) {
                <span [attr.data-test]="'desc-excludesVat'">
                  {{ 'checkout-delivery-details.price-info' | translate }}
                </span>
              } @else {
                <span [attr.data-test]="'desc-excludesVat'">
                  {{ 'shop-cart.excludes-vat' | translate }}
                </span>
              }
              <br/>
              <span [attr.data-test]="'desc-pricingValidation'">
                {{ 'shop-cart.pricing-validation' | translate }}
              </span>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="col-12 col-lg-10">
            <span [attr.data-test]="'label-selectedSystem'">
              {{ 'request-tracking.selected-system' | translate }}
            </span>
            <span *ngIf="quoteDetails.attributes.systemDetails" [attr.data-test]="'desc-selectedSystem'">
              {{ quoteDetails.attributes.systemDetails | selectedSystem | async }}
            </span>
          </div>
          <div class="col-12 col-lg-10">
            <span [attr.data-test]="'label-company'">
              {{ 'request-tracking.company' | translate }}
            </span>
            <span *ngIf="quoteDetails.attributes.systemDetails" [attr.data-test]="'desc-company'">
              {{ quoteDetails.attributes.systemDetails | companyDetails }}
            </span>
          </div>
          <div class="col-12 col-lg-10">
            <span [attr.data-test]="'label-customerName'">
              {{ 'request-tracking.contact' | translate }}
            </span>
            <span [attr.data-test]="'desc-customerName'">
              {{ quoteDetails.attributes.pointOfContact.firstName }} {{ quoteDetails.attributes.pointOfContact.lastName }}
            </span>
          </div>
          <div class="col-12 col-lg-10">
            <span [attr.data-test]="'label-customerPhone'">
              {{ 'request-tracking.contact-tel' | translate }}
            </span>
            <span [attr.data-test]="'desc-customerPhone'">
              {{ quoteDetails.attributes.pointOfContact.phone }}
            </span>
          </div>
          <div class="col-12 col-lg-10">
            <span [attr.data-test]="'label-customerEmail'">
              {{ 'request-tracking.contact-email' | translate }}
            </span>
            <span [attr.data-test]="'desc-customerEmail'">
              {{ quoteDetails.attributes.pointOfContact.email }}
            </span>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-12 col-lg-8 items-border-styling">
          <ng-container *ngFor="let item of quoteDetails.attributes.shownVersion.cart.items">
            <div class="item-border-styling">
              <div class="row item-row-styling" [attr.data-test]="'request-item-' + item.name">
                <div class="col-2">
                  <img src="{{ item.metadata.image }}" [alt]="item.name">
                </div>
                <div class="col-8">
                  <h6 class="item-name-styling" [attr.data-test]="'title-name'">
                    {{ item.name }}
                  </h6>
                  <div *ngIf="isGeneratedQuoteWorkflow()">
                    <h5 class="item-price" [attr.data-test]="'title-price'">
                      {{ item.calculations.unitPrice | currency: quoteDetails.attributes.shownVersion.cart.currency }}
                    </h5>
                  </div>
                  <div *ngIf="item.systemDetails">
                    <div class="padding-styling">
                      <span [attr.data-test]="'label-selectedSystem'">
                        {{ 'request-tracking.selected-system' | translate }}
                      </span>
                      <div class="item-system-details-styling" >
                        <span *ngIf="item.systemDetails" [attr.data-test]="'desc-selectedSystem'">
                          {{ item.systemDetails | selectedSystem | async }}
                        </span>
                      </div>
                    </div>
                    <div class="padding-styling">
                      <span [attr.data-test]="'label-company'">
                        {{ 'request-tracking.company' | translate }}
                      </span>
                      <div class="item-system-details-styling">
                        <span *ngIf="item.systemDetails" [attr.data-test]="'desc-company'">
                          {{ item.systemDetails | companyDetails }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div [attr.data-test]="'desc-quantity'">
                    {{ item.quantity }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-12 col-lg-4 address-styling">
          <strong [attr.data-test]="'label-address'">
            {{ 'order-tracking-history.delivery-address' | translate }}
          </strong>
          <div>
            <div *ngIf="quoteDetails.attributes.shippingAddress.firstName !== 'empty'"
                 [attr.data-test]="'desc-customerName'">
              {{ quoteDetails.attributes.shippingAddress.firstName }}
            </div>
            <div [attr.data-test]="'desc-address1'">
              {{ quoteDetails.attributes.shippingAddress.address1 }}
            </div>
            <div *ngIf="quoteDetails.attributes.shippingAddress.address3 !== 'empty'"
                 [attr.data-test]="'desc-address3'">
              {{ quoteDetails.attributes.shippingAddress.address3 }}
            </div>
            <div class="address-styling--inline">
              <div *ngIf="quoteDetails.attributes.shippingAddress.zipCode !== 'empty'"
                   [attr.data-test]="'desc-zipCode'">
                {{ quoteDetails.attributes.shippingAddress.zipCode }}&nbsp;
              </div>
              <div *ngIf="quoteDetails.attributes.shippingAddress.city !== 'empty'"
                   [attr.data-test]="'desc-city'">
                {{ quoteDetails.attributes.shippingAddress.city }}
              </div>
              <div *ngIf="quoteDetails.attributes.shippingAddress.address2 !== 'empty'"
                   [attr.data-test]="'desc-address2'">
                , {{ quoteDetails.attributes.shippingAddress.address2 }}
              </div>
            </div>
            <div *ngIf="quoteDetails.attributes.shippingAddress.country !== 'empty'"
                 [attr.data-test]="'desc-country'">
              {{ quoteDetails.attributes.shippingAddress.country }}
            </div>
          </div>
          <div *ngIf="quoteDetails.attributes.taxNumber" class="cart-tax-number">
            <ng-container>
              <strong [attr.data-test]="'label-taxNumber'">
                {{ 'order-approve.billing-tax-number' | translate }}
              </strong>
              <div [attr.data-test]="'desc-taxNumber'">
                {{ quoteDetails.attributes.taxNumber }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-modal
    [id]="'cancel-request-modal'"
    [open]="showModalCancelOrder"
    [title]="'request-tracking.cancel-request-modal-title' | translate"
    (crossClick)="restartShowModal()">
  <ng-container ngProjectAs="[slot][body]">
    <h5 class="cancel-request-modal-body">
      {{ 'request-tracking.cancel-request-modal-body' | translate:{number: orderId} }}
    </h5>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <button type="button" (click)="restartShowModal()" class="button button" data-dismiss="modal-dialog">
      {{ 'request-tracking.cancel-request-modal-abandon-btn' | translate }}
    </button>
    <button
        type="button"
        class="button button--primary"
        data-dismiss="modal-dialog"
        (click)="cancelQuoteRequest(orderId)">
      {{ 'request-tracking.cancel-request-modal-ok-btn' | translate }}
    </button>
  </ng-container>
</app-modal>
