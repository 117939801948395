import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ISelectBoxOption, ISelectEvent } from '../../../../../models/common.models';
import { FormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CheckoutFacade } from '../../../../../facades/checkout.facade';
import { EApproverInputNames, EGlueResource } from '../../../../../configurations/common';
import * as ValidationPatterns from '../../../../../configurations/validations';
import { ICartVoucher } from '../../../../../models/cart.models';
import { GlueUtils } from '../../../../../utils/glue.utils';
import { MarketingFacade } from '../../../../../facades/marketing.facade';
import { Store } from '@ngrx/store';
import { ShopCartActions } from '../../../../../actions';

@Component({
  selector: 'app-dynamic-checkout-approver-form',
  templateUrl: './dynamic-checkout-approver-form.component.html',
  styleUrl: './dynamic-checkout-approver-form.component.scss',
})
export class DynamicCheckoutApproverFormComponent implements OnInit, OnDestroy {
  protected readonly EApproverInputNames = EApproverInputNames;

  @Input() cartId: string;
  @Input() requiredInputs: string[];
  @Input() visibleInputs: string[];
  @Input() readOnlyInputs: string[];
  @Input() useGenerateQuoteFlow: boolean = false;
  @Input() useRfqFlow: boolean = false;

  @Output() formValidityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedApproverEmit: EventEmitter<string> = new EventEmitter<string>();

  approverForm: UntypedFormGroup;
  approversNameList: ISelectBoxOption[] = [];
  approversNameListLoading: boolean = true;
  commentMaxLength: number = 500;

  voucherCodeInputVisible: boolean = false;
  // TODO: Consider if these 3 flags actually make sense within dynamic form component or checkout state
  voucherListLoaded: boolean = false;
  voucherHasError: boolean = false;
  addVoucherInProgress: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store,
    private checkoutFacade: CheckoutFacade,
    private marketingFacade: MarketingFacade,
    private formBuilder: FormBuilder,
  ) {
  }

  /**
   * Initializes the component by setting up the form.
   */
  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Cleans up the component by completing the unsubscribe$ subject.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Initializes the form, sets up the required validators dynamically based on Arakh config and prefill the form with data.
   */
  initializeForm(): void {
    const formGroupConfig = {};
    const inputConfigMap = {
      [EApproverInputNames.APPROVER]: ['', [...this._getRequiredValidator(EApproverInputNames.APPROVER)]],
      [EApproverInputNames.COMMENT]: ['', [
        ...this._getRequiredValidator(EApproverInputNames.COMMENT),
        Validators.maxLength(this.commentMaxLength),
      ]],
      [EApproverInputNames.PO_NUMBER]: ['', [
        ...this._getRequiredValidator(EApproverInputNames.PO_NUMBER),
        ValidationPatterns.noEmptySpaceOnTheBeginning,
      ]],
      [EApproverInputNames.PROMO_CODE]: ['', [...this._getRequiredValidator(EApproverInputNames.PROMO_CODE)]],
    };

    this.visibleInputs.forEach(input => {
      if (inputConfigMap[input]) {
        formGroupConfig[input] = inputConfigMap[input];
      }
    });

    this.approverForm = this.formBuilder.group(formGroupConfig);

    //emitting because of init state of the form
    this.formValidityChange.emit(this.approverForm.valid);
    this._checkFormValidity();
    this._prefillForm();
  }

  /**
   * Checks the form validity.
   *
   * @private
   */
  private _checkFormValidity(): void {
    this.approverForm.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.formValidityChange.emit(this.approverForm.valid);
    });
  }

  /**
   * Prefills the form with the cached data.
   *
   * @private
   */
  private _prefillForm(): void {
    this.checkoutFacade.selectDeliveryDetailsApproverFormData().pipe(take(1)).subscribe(data => {
      this.getApprovers(data?.approverId);
      this.visibleInputs.forEach(visibleInput => {
        switch (visibleInput) {
          case EApproverInputNames.COMMENT:
            this.approverForm.patchValue({comment: data?.comment || ''});
            break;
          case EApproverInputNames.PO_NUMBER:
            this.approverForm.patchValue({poNumber: data?.poNumber || ''});
            break;
        }
      });
    });
  }

  /**
   * Gets the required validators for the given input name based on Arakh config.
   *
   * @private
   *
   * @param {string} inputName
   * @returns {ValidatorFn[]}
   */
  private _getRequiredValidator(inputName: string): ValidatorFn[] {
    return this.requiredInputs?.includes(inputName) ? [Validators.required] : [];
  }

  /**
   * Gets the list of approvers and preselects the approver if it was already selected.
   *
   * @param {string} approverId
   */
  getApprovers(approverId: string): void {
    this.checkoutFacade.getCompanyUsersForApprove().pipe(take(1)).subscribe(response => {
      const userIdMap = response.data.filter(user => user.attributes.isActive === true).map(user => ({
        id: user.id,
        customerIds: user.relationships.customers.data.map(rel => rel.id),
      }));

      userIdMap.forEach(user => {
        const includedUser = response.included.find(i => i.id === user.customerIds[0]).attributes;
        this.approversNameList.push({
          value: {
            id: user.id,
            firstName: includedUser.firstName,
            lastName: includedUser.lastName,
          },
          name: `${includedUser.firstName} ${includedUser.lastName}`,
        });
      });

      if (approverId) {
        const preselectedApprover = this.approversNameList?.find(approver => approver?.value?.id === approverId);
        if (preselectedApprover) {
          this.approverForm.patchValue({
            [EApproverInputNames.APPROVER]: preselectedApprover,
          });
          this.selectedApproverEmit.emit(preselectedApprover?.name);
        }
      }

      this.approversNameListLoading = false;
    });
  }

  /**
   * Selects the approver and updates the form.
   *
   * @param {ISelectEvent} event
   */
  selectApprover(event: ISelectEvent): void {
    this.approverForm.patchValue({
      [event.key]: event,
    });
    this.selectedApproverEmit.emit(event?.name);
  }

  /**
   * Sets the approver form value.
   *
   * @param {ISelectEvent} event
   */
  setApproverFormValue(event: ISelectEvent): void {
    this.approverForm.patchValue({
      [event.key]: event.value,
    });
  }

  /**
   * Caches the checkout approver form data.
   */
  cacheCheckoutApproverFormData(): void {
    this.checkoutFacade.setDeliveryDetailsApproverFormData({
      approverId: this.approverForm.value?.approver?.value?.id ?? '',
      firstName: this.approverForm.value?.approver?.value?.firstName ?? '',
      lastName: this.approverForm.value?.approver?.value?.lastName ?? '',
      dueDate: null,
      comment: this.approverForm.value?.comment ?? '',
      poNumber: this.approverForm.value?.poNumber ?? '',
    });
  }

  /**
   * Adds a voucher to the cart.
   */
  addVoucher(): void {
    this.addVoucherInProgress = true;
    this.checkoutFacade.setCheckoutTotalPricesLoading(true);
    this.marketingFacade.postVoucher(this.cartId, this.approverForm.value.promoCode).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: response => {
        this.addVoucherInProgress = false;
        this.voucherListLoaded = true;
        this.voucherHasError = false;
        this.approverForm.patchValue({promoCode: ''});
        this.checkoutFacade.setCheckoutTotalPricesLoading(false);
        this.checkoutFacade.setCheckoutTotalPrices(response.data.attributes.totals);
        this.store.dispatch(ShopCartActions.loadCartItemsSuccess({cartItems: response}));
        const vouchers = GlueUtils.filterResourcesByType(
          response.included, EGlueResource.VOUCHERS,
        ) as ICartVoucher[];
        this.checkoutFacade.setCartVouchers(vouchers);
      },
      error: () => {
        this.checkoutFacade.setCheckoutTotalPricesLoading(false);
        this.addVoucherInProgress = false;
        this.voucherListLoaded = false;
        this.voucherHasError = true;
      },
    });
  }

  /**
   * Resets the voucher field state.
   */
  resetVoucherField(): void {
    this.voucherListLoaded = false;
    this.voucherHasError = false;
  }

  /**
   * Checks if the voucher button should be disabled.
   *
   * It should return true if:
   * - add voucher is in progress
   * - voucher code input form field is empty
   *
   * Otherwise, it should return false.
   *
   * @returns {boolean}
   */
  isVoucherBtnDisabled(): boolean {
    return this.addVoucherInProgress || this.approverForm.value.promoCode === '';
  }

  /**
   * Expands voucher form after clicking on the voucher button.
   *
   * It should not expand voucher form if request quote flow is used
   * as voucher code cannot be applied on cart with no price set.
   *
   * It should only expand voucher form in the case of regular flow or generate quote flow.
   */
  expandVoucherForm(): void {
    if (!(this.useRfqFlow && !this.useGenerateQuoteFlow)) {
      this.voucherCodeInputVisible = true;
    }
  }
}
